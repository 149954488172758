import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import classNames from 'classnames';
import AppContext from 'context/Context';
import PropTypes from 'prop-types';

import Footer from 'blockscope/components/footer/Footer';
import NavbarTop from 'blockscope/components/navbar/top/NavbarTop';
import NavbarVertical from 'blockscope/components/navbar/vertical/NavbarVertical';

const MainLayout = ({ orgState: { orgDetails } }) => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');

  const [orgServices, setOrgServices] = useState(orgDetails.org.orgServices);
  const [solution, setSolution] = useState(orgDetails.org.solution);

  const {
    config: { isFluid, navbarPosition },
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (orgDetails.org.orgServices) {
      setOrgServices(orgDetails.org.orgServices);
    } else {
      setOrgServices([]);
    }
    if (orgDetails.org.solution) {
      setSolution(orgDetails.org.solution);
    } else {
      setSolution('');
    }
  }, [orgDetails]);

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical services={orgServices} solution={solution} />
      )}
      <div className={classNames('content', { 'pb-0': isKanban })}>
        <NavbarTop />
        {/*------ Main Routes ------*/}

        <Outlet />
        {!isKanban && <Footer />}
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  orgState: PropTypes.shape({
    orgDetails: PropTypes.shape({
      org: PropTypes.shape({
        solution: PropTypes.string,
        orgServices: PropTypes.arrayOf(PropTypes.string).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  orgState: state.orgReducer,
});

export default connect(mapStateToProps, {})(MainLayout);
