import { allSessionsResponse, sessionResponse } from './zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { queryClient } from 'stateManagement/queries/client';

import { AI_CHAT_TOAST_ID } from 'blockscope/static/toastIds';

import { errorToast, successToast } from 'blockscope/utils/toastHandler';

const getAllChatSessionsEndpoint = async () => {
  const response = await axios.get('/api/v2/ai-chat/session/');

  return response.data;
};

export const usegetAllChatSessions = () => {
  return useQuery({
    queryKey: ['chat-sessions'],
    queryFn: async () => {
      const data = await getAllChatSessionsEndpoint();
      const parseResult = allSessionsResponse.safeParse(data.data);

      if (!parseResult.success) {
        throw new Error(`Failed to parse response: ${parseResult.error}`);
      }

      return parseResult.data;
    },
    cacheTime: 24 * 60 * 60 * 1000,
    staleTime: 24 * 60 * 60 * 1000,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
};

const getSessionByIdEndpoint = async (sessionId) => {
  const response = await axios.get(`/api/v2/ai-chat/session/${sessionId}`);

  return response.data;
};

export const useGetSessionById = (sessionId) => {
  return useQuery({
    queryKey: ['chat-session', sessionId],
    queryFn: async () => {
      const data = await getSessionByIdEndpoint(sessionId);
      const parseResult = sessionResponse.safeParse(data.data);

      if (!parseResult.success) {
        throw new Error(`Failed to parse response: ${parseResult.error}`);
      }

      return parseResult.data;
    },
    cacheTime: 24 * 60 * 60 * 1000,
    staleTime: 24 * 60 * 60 * 1000,
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });
};

export const useCreateSession_Mutation = () => {
  return useMutation(
    (name) =>
      axios
        .post(`/api/v2/ai-chat/create-session/`, { name })
        .then((res) => res.data),
    {
      onSuccess: (data) => {
        const allSessions = queryClient.getQueryData(['chat-sessions']);

        if (allSessions) {
          queryClient.setQueryData(['chat-sessions'], (oldData) => [
            ...oldData,
            data.data,
          ]);
        }
      },
    }
  );
};

export const useDeleteSession_Mutation = () => {
  return useMutation(
    /** @param {String} sessionId */
    (sessionId) =>
      axios
        .delete(`/api/v2/ai-chat/session/${sessionId}`)
        .then((res) => res.data),
    {
      onSuccess: (data, inputData) => {
        const allSessions = queryClient.getQueryData(['chat-sessions']);

        if (allSessions) {
          queryClient.setQueryData(['chat-sessions'], (oldData) =>
            oldData.filter((session) => session.sessionId !== inputData)
          );
        }
        successToast('Deleted session', AI_CHAT_TOAST_ID.SUCCESS.DELETE);
      },
      onError: (error) => {
        errorToast(
          error.response?.data?.error?.message || error.message,
          AI_CHAT_TOAST_ID.ERROR.DELETE
        );
      },
    }
  );
};

export const useUploadImageToS3_Mutation = () => {
  return useMutation(
    /**
     *
     * @param {{sessionId: String, images:File[]}} param0
     * @returns
     */
    ({ sessionId, images }) =>
      Promise.all(
        images.map((img) => {
          const formData = new FormData();
          formData.append('files', img);

          return axios
            .post(`/api/v2/ai-chat/upload/${sessionId}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((res) => res.data.data);
        })
      )
  );
};
