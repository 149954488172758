// workflowFunctions.js
import { workflowTypes } from './workflowTypes';

import { SHERLOCK_ACTIONS } from '../../../../../stateManagement/redux/actionPlans/types';

/**
 * Parses a component ID string to extract tab name
 * @param {string} componentId - Format: 'profiler-{address}-{chain}-{tab}'
 * @returns {Object} Parsed component details
 */
const parseComponentId = (componentId) => {
  const parts = componentId.split('-');
  if (parts.length !== 4) {
    throw new Error(`Invalid component ID format: ${componentId}`);
  }
  return {
    type: parts[0],
    address: parts[1],
    chain: parts[2],
    tab: parts[3],
  };
};

/**
 * Creates an action plan to investigate a full wallet profile based on available tabs
 * @param {string} chain - The blockchain network (e.g., 'ethereum')
 * @param {string} address - The wallet address to investigate
 * @param {Object} state - Redux state
 * @returns {Object} Action plan configuration
 */
export const createFullWalletProfilePlan = (
  chain = 'ethereum',
  address,
  state
) => {
  const availableComponents = state.componentTracker.activeComponents;

  if (!availableComponents || availableComponents.length === 0) {
    console.warn('No components available in Redux state');
    return {
      name: `Full Wallet Profile Investigation - ${address}`,
      sherlockActions: [],
      prompt:
        "Conduct a comprehensive analysis of all wallet activities and characteristics. Review all available tabs to build a complete profile of the wallet's behavior, interactions, and patterns.",
    };
  }

  // Parse and filter components for this address and chain
  const relevantComponents = availableComponents.filter((componentId) => {
    try {
      const parsed = parseComponentId(componentId);
      return (
        parsed.type === 'profiler' &&
        parsed.address.toLowerCase() === address.toLowerCase() &&
        parsed.chain.toLowerCase() === chain.toLowerCase()
      );
    } catch (error) {
      console.warn(`Skipping invalid component ID: ${componentId}`, error);
      return false;
    }
  });

  const availableTabs = relevantComponents.map((componentId) => {
    const { tab } = parseComponentId(componentId);
    return tab;
  });

  const sherlockActions = availableTabs.map((tab) => ({
    type: SHERLOCK_ACTIONS.OPEN_PROFILER_TAB,
    params: {
      chain,
      address,
      tab,
    },
  }));

  return {
    name: `Full Wallet Profile Investigation - ${address}`,
    sherlockActions: sherlockActions,
    prompt:
      "Conduct a comprehensive analysis of all wallet activities and characteristics. Review all available tabs to build a complete profile of the wallet's behavior, interactions, and patterns.",
  };
};

/**
 * Helper function to check if tabs are available for a specific address and chain
 * @param {Object} state - Redux state
 * @param {string} address - Wallet address
 * @param {string} chain - Blockchain network
 * @returns {boolean}
 */
export const areTabsAvailable = (state, address, chain) => {
  if (!state.componentTracker.activeComponents) return false;

  return state.componentTracker.activeComponents.some((componentId) => {
    try {
      const parsed = parseComponentId(componentId);
      return (
        parsed.type === 'profiler' &&
        parsed.address.toLowerCase() === address.toLowerCase() &&
        parsed.chain.toLowerCase() === chain.toLowerCase()
      );
    } catch {
      return false;
    }
  });
};

/**
 * Creates an action plan focusing on KYC-relevant tabs
 * @param {string} chain - The blockchain network
 * @param {string} address - The wallet address to investigate
 * @param {Object} state - Redux state
 * @returns {Object} Action plan configuration
 */
export const createKYCInvestigationPlan = (
  chain = 'ethereum',
  address,
  state
) => {
  // KYC-relevant tabs in priority order
  const KYC_PRIORITY_TABS = ['LABELS', 'COUNTER_PARTIES', 'OVERVIEW'];

  const availableComponents = state.componentTracker.activeComponents;

  if (!availableComponents || availableComponents.length === 0) {
    console.warn('No components available in Redux state');
    return {
      name: `KYC Investigation - ${address}`,
      sherlockActions: [],
      prompt:
        'Investigate wallet for potential KYC identifiers and relationships. Focus on labels, counterparty interactions, and general wallet behavior to identify any patterns suggesting real-world identity.',
    };
  }

  // Parse and filter components for this address and chain
  const relevantComponents = availableComponents.filter((componentId) => {
    try {
      const parsed = parseComponentId(componentId);
      return (
        parsed.type === 'profiler' &&
        parsed.address.toLowerCase() === address.toLowerCase() &&
        parsed.chain.toLowerCase() === chain.toLowerCase()
      );
    } catch (error) {
      console.warn(`Skipping invalid component ID: ${componentId}`, error);
      return false;
    }
  });

  const availableTabs = relevantComponents.map((componentId) => {
    const { tab } = parseComponentId(componentId);
    return tab;
  });

  // Only include KYC priority tabs that exist in available tabs
  const sortedTabs = KYC_PRIORITY_TABS.filter((tab) =>
    availableTabs.includes(tab)
  );

  const sherlockActions = sortedTabs.map((tab) => ({
    type: SHERLOCK_ACTIONS.OPEN_PROFILER_TAB,
    params: {
      chain,
      address,
      tab,
    },
  }));

  return {
    name: `KYC Investigation - ${address}`,
    sherlockActions: sherlockActions,
    prompt:
      'Investigate wallet for potential KYC identifiers and relationships. Focus on labels, counterparty interactions, and general wallet behavior to identify any patterns suggesting real-world identity.',
  };
};

export const createDecodeTraceCallPlan = (
  chain = 'ethereum',
  txnHash,
  state
) => {
  // KYC-relevant tabs in priority order
  const GO_TO_TABS = ['TRACE_CALL'];

  const actionPlan = GO_TO_TABS.map((tab) => ({
    type: SHERLOCK_ACTIONS.OPEN_DECODER_TAB,
    params: {
      chain,
      txnHash,
      tab,
    },
  }));

  return {
    name: `Understand Trace - ${txnHash}`,
    sherlockActions: actionPlan,
    prompt:
      'Explain this trace call for this transaction. Break it down and explain it in detail.',
  };
};

/**
 * Creates an action plan to investigate a full contract analysis based on available tabs
 * @param {string} chain - The blockchain network (e.g., 'ethereum')
 * @param {string} address - The contract address to investigate
 * @param {Object} state - Redux state
 * @returns {Object} Action plan configuration
 */
export const createFullContractAnalysisPlan = (
  chain = 'ethereum',
  address,
  state
) => {
  const availableComponents = state.componentTracker.activeComponents;

  if (!availableComponents || availableComponents.length === 0) {
    console.warn('No components available in Redux state');
    return {
      name: `Full Contract Breakdown Investigation - ${address}`,
      sherlockActions: [],
      prompt:
        "Conduct a comprehensive analysis of all Contract details. Review all available tabs to build a complete profile of the Contract's ABI, Functions, Events, Constructor, and Errors.",
    };
  }

  // Parse and filter components for this address and chain
  const relevantComponents = availableComponents.filter((componentId) => {
    try {
      const parsed = parseComponentId(componentId);
      return (
        parsed.type === 'contractAnalysis' &&
        parsed.address.toLowerCase() === address.toLowerCase() &&
        parsed.chain.toLowerCase() === chain.toLowerCase()
      );
    } catch (error) {
      console.warn(`Skipping invalid component ID: ${componentId}`, error);
      return false;
    }
  });

  const availableTabs = relevantComponents.map((componentId) => {
    const { tab } = parseComponentId(componentId);
    return tab;
  });

  const sherlockActions = availableTabs.map((tab) => ({
    type: SHERLOCK_ACTIONS.OPEN_CONTRACT_ANALYSIS_TAB,
    params: {
      chain,
      address,
      tab,
    },
  }));

  // dispatch go to txn decoder, txn tab, search binance, take ss
  // collect data, add to master arrayy
  // dispatch 2nd

  return {
    name: `Full Contract Breakdown Investigation - ${address}`,
    sherlockActions: sherlockActions,
    prompt:
      "Conduct a comprehensive analysis of all Contract details. Review all available tabs to build a complete profile of the Contract's ABI, Functions, Events, Constructor, and Errors.",
  };
};
export const AnalyzeCurrentPagePlan = () => {
  return {
    name: `Current Page`,
    sherlockActions: [
      {
        type: SHERLOCK_ACTIONS.CURRENT_PAGE,
        params: {},
      },
    ],
    prompt: 'Explain The information on the current page in detail.',
  };
};

export const createFullDecoderPlan = (chain = 'ethereum', txnHash, state) => {
  // KYC-relevant tabs in priority order
  const GO_TO_TABS = [
    'ANALYTICS',
    'ADDRESS_INTERACTION',
    'BALANCE_DIFFERENCES',
    'TOKEN_TRANSFERS',
    'LOGS',
    'FLOW_CHART',
    'TRACE_CALL',
    'SECURITY_RISK',
  ];

  const actionPlan = GO_TO_TABS.map((tab) => ({
    type: SHERLOCK_ACTIONS.OPEN_DECODER_TAB,
    params: {
      chain,
      txnHash,
      tab,
    },
  }));

  return {
    name: `Full Txn Decode - ${txnHash}`,
    sherlockActions: actionPlan,
    prompt:
      'Conduct a comprehensive analysis of this decoded transaction data. Review all available tabs to build a complete understanding of what this transaction is, how it works, who was involved and any other details or patterns you can find',
  };
};

export const handleWorkflowTrigger = (
  workflowType,
  location,
  state,
  chatSessionId
) => {
  const getWalletDetailsFromUrl = (location) => {
    const pathParts = location.split('/');
    if (pathParts.length >= 4) {
      return {
        chain: pathParts[2],
        address: pathParts[3].split('?')[0],
      };
    }
    return null;
  };

  const getContractAnalysisFromUrl = (location) => {
    const pathParts = location.split('/');
    if (pathParts.length >= 4) {
      return {
        chain: pathParts[2],
        address: pathParts[3].split('?')[0],
      };
    }
    return null;
  };

  const getTxnDetailsFromUrl = (location) => {
    const pathParts = location.split('/');
    if (pathParts.length >= 4) {
      return {
        chain: pathParts[2],
        txnHash: pathParts[3].split('?')[0],
      };
    }
    return null;
  };

  let plan;

  switch (workflowType) {
    case workflowTypes.INVESTIGATE_FULL_WALLET_PROFILE:
    case workflowTypes.INVESTIGATE_WALLET_FOR_KYC_DATA:
    case workflowTypes.INVESTIGATE_WALLET_FOR_ILLICIT_ACTIVITY: {
      const walletDetails = getWalletDetailsFromUrl(location);
      if (walletDetails) {
        plan = workflowFunctions[workflowType](
          walletDetails.chain,
          walletDetails.address,
          state
        );
      }
      break;
    }

    case workflowTypes.INVESTIGATE_FULL_DECODER:
    case workflowTypes.ANALYZE_DECODER_TRACE_CALL: {
      const txnDetails = getTxnDetailsFromUrl(location);
      if (txnDetails) {
        plan = workflowFunctions[workflowType](
          txnDetails.chain,
          txnDetails.txnHash,
          state
        );
      }
      break;
    }

    case workflowTypes.INVESTIGATE_FULL_CONTRACT_ANALYSIS: {
      const contractDetails = getContractAnalysisFromUrl(location);
      if (contractDetails) {
        plan = workflowFunctions[workflowType](
          contractDetails.chain,
          contractDetails.address,
          state
        );
      }
      break;
    }

    case workflowTypes.ANALYZE_CURRENT_PAGE: {
      plan = workflowFunctions[workflowType]();
      break;
    }

    default:
      console.warn('Unknown workflow type:', workflowType);
      return null;
  }

  if (plan) {
    plan.chatSessionId = chatSessionId;
    return plan;
  } else {
    console.warn('Could not extract required details from URL');
    return null;
  }
};

// Update the workflowActionCreators
export const workflowFunctions = {
  INVESTIGATE_FULL_WALLET_PROFILE: createFullWalletProfilePlan,
  INVESTIGATE_FULL_DECODER: createFullDecoderPlan,

  INVESTIGATE_WALLET_FOR_KYC_DATA: createKYCInvestigationPlan,
  ANALYZE_DECODER_TRACE_CALL: createDecodeTraceCallPlan,
  INVESTIGATE_FULL_CONTRACT_ANALYSIS: createFullContractAnalysisPlan,
  ANALYZE_CURRENT_PAGE: AnalyzeCurrentPagePlan,
};

