// actions/componentTrackerActions.js
export const ADD_COMPONENT = 'ADD_COMPONENT';
export const REMOVE_COMPONENT = 'REMOVE_COMPONENT';

export const addComponent = (componentId) => ({
  type: ADD_COMPONENT,
  payload: componentId,
});

export const removeComponent = (componentId) => ({
  type: REMOVE_COMPONENT,
  payload: componentId,
});

