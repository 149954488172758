import React from 'react';
import { useParams } from 'react-router-dom';

import ChatWindow from '../components/ChatWindow';

const ChatPage = () => {
  const routeParams = useParams();
  return (
    <>
      <ChatWindow sessionId={routeParams.sessionId} />
    </>
  );
};

export default ChatPage;

