import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { workflowConfigs } from '../core/workflowConfigs';
import { handleWorkflowTrigger } from '../core/workflowFunctions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useChatPanelStore from 'stateManagement/store/useChatPanelStore';

import { useGetSessionById } from 'stateManagement/queries/services/aiChat/aiChatQueries';

import { createActionPlan } from 'stateManagement/redux/actionPlans/slice';

const WorkflowDropdown = ({ authReducer: { user } }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const chatSessionId = useChatPanelStore((state) => state.chatSessionId);
  const { data: chatData } = useGetSessionById(chatSessionId);

  const scrollToBottom = (smooth = true) => {
    setTimeout(() => {
      const parentDiv = document.querySelector('#scrollable-panel-content');
      if (parentDiv) {
        parentDiv.style.overflow = 'auto';
        parentDiv.scrollTo({
          top: parentDiv.scrollHeight,
          behavior: smooth ? 'smooth' : 'auto',
        });
      }
    }, 100);
  };

  const onWorkflowClick = (workflowId) => {
    const plan = handleWorkflowTrigger(
      workflowId,
      location.pathname,
      state,
      chatSessionId
    );
    if (plan) {
      dispatch(createActionPlan(plan));
      scrollToBottom();
    }
  };

  // Filter workflows based on current path
  const workflowOptions = workflowConfigs.filter(
    (workflow) =>
      location.pathname.includes(workflow.path) &&
      location.pathname !== workflow.path &&
      location.pathname !== `${workflow.path}/`
  );

  return chatData?.createdBy?._id !== user?._id &&
    workflowOptions.length > 0 ? null : (
    <Dropdown drop='up'>
      <style>{`
        .no-arrow::after {
          display: none !important;
        }
      `}</style>

      <Dropdown.Toggle
        variant='falcon-primary'
        id='chat-sessions-dropdown'
        size='sm'
        className='no-arrow'
      >
        <FontAwesomeIcon icon='list-check' />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Header>Workflows</Dropdown.Header>

        {workflowOptions.length > 0 ? (
          workflowOptions.map((workflow) => (
            <Dropdown.Item
              key={workflow.id}
              onClick={() => onWorkflowClick(workflow.workflowId)}
            >
              <div className='d-flex align-items-center justify-content-between gap-2'>
                {workflow.buttonText}
              </div>
            </Dropdown.Item>
          ))
        ) : (
          <Dropdown.Item disabled>No workflows</Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = (state) => ({
  authReducer: state.authReducer,
});

export default connect(mapStateToProps, {})(WorkflowDropdown);
