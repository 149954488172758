import { workflowTypes } from './workflowTypes';

// Define workflows configuration
export const workflowConfigs = [
  {
    id: 'wallet',
    path: '/wallet-profile',
    buttonText: 'Full Wallet Profile',
    icon: 'wallet',
    variant: 'falcon-default',
    workflowId: workflowTypes.INVESTIGATE_FULL_WALLET_PROFILE,
  },
  {
    id: 'wallet',
    path: '/wallet-profile',
    buttonText: 'Investigate Wallet for KYC Data',
    icon: 'wallet',
    variant: 'falcon-default',
    workflowId: workflowTypes.INVESTIGATE_WALLET_FOR_KYC_DATA,
  },

  {
    id: 'transaction',
    path: '/transaction-decoder',
    buttonText: 'Decode and Explain Transaction',
    icon: 'wallet',
    variant: 'falcon-default',
    workflowId: workflowTypes.INVESTIGATE_FULL_DECODER,
  },
  {
    id: 'transaction',
    path: '/transaction-decoder',
    buttonText: 'Analyze trace call',
    icon: 'wallet',
    variant: 'falcon-default',
    workflowId: workflowTypes.ANALYZE_DECODER_TRACE_CALL,
  },
  {
    id: 'contractAnalysis',
    path: '/contract-analysis',
    buttonText: 'Analyze Contract Structure',
    icon: 'puzzle-piece',
    variant: 'falcon-default',
    workflowId: workflowTypes.INVESTIGATE_FULL_CONTRACT_ANALYSIS,
  },
  {
    id: 'currentPage',
    path: '',
    buttonText: 'Analyze current page',
    icon: 'file',
    variant: 'falcon-primary',
    workflowId: workflowTypes.ANALYZE_CURRENT_PAGE,
  },
];

