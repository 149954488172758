import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { useCreateSession_Mutation } from 'stateManagement/queries/services/aiChat/aiChatQueries';

import ControlledInput from 'blockscope/components/blockscopeCommon/ControlledInput';

const formSchema = z.object({
  sessionName: z
    .string()
    .min(3, { message: 'sessionName must be at least 3 characters' })
    .max(50, { message: 'sessionName must be less than 50 characters' }),
});
const CreateSessionForm = () => {
  const { mutate: createNewChatSession } = useCreateSession_Mutation();

  const {
    formState: { errors },
    control,
    handleSubmit,
  } = useForm({
    defaultValues: { sessionName: '' },
    resolver: zodResolver(formSchema),
  });

  const onSubmit = (formData) => {
    createNewChatSession(formData.sessionName);
  };
  return (
    <>
      <Form>
        <Form.Label>Session Name</Form.Label>
        <ControlledInput
          paramName='sessionName'
          control={control}
          type='text'
        />
        {errors?.sessionName && (
          <p className='text-danger fs--2 my-0'>
            {errors.sessionName?.message}
          </p>
        )}
      </Form>

      <Button
        variant='falcon-primary'
        onClick={handleSubmit(onSubmit)}
        className='mt-3'
      >
        Create
      </Button>
    </>
  );
};

export const CreateSessionModal = () => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button variant='falcon-primary' onClick={() => setShow(true)}>
        Create New Session
      </Button>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateSessionForm />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateSessionForm;
