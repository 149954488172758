import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';

import PropTypes from 'prop-types';

function getNestedProperty(obj, propertyPath) {
  const properties = propertyPath.split('.');
  let currentObj = obj;

  for (const property of properties) {
    if (!currentObj || !(property in currentObj)) {
      return '';
    }
    currentObj = currentObj[property];
  }
  if (Array.isArray(currentObj)) {
    return currentObj.join(' ');
  }
  return currentObj;
}
export const Searchable = ({
  searchableFields = [],
  isSearchable = true,
  initialSearch,
  data,
  children,
}) => {
  const [searchText, setSearchText] = useState('');
  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => {
    initialSearch && setSearchText(initialSearch);
  }, [initialSearch]);

  useEffect(() => {
    setFilteredRows(
      data?.filter((row) =>
        searchableFields.reduce(
          (prev, curr) =>
            getNestedProperty(row, curr)
              .toLowerCase()
              .includes(searchText.toLowerCase()) || prev,
          false
        )
      ) || []
    );
  }, [searchText, data, searchableFields]);

  return React.cloneElement(children, {
    searchText,
    setSearchText,
    data: filteredRows,
    isSearchable,
  });
};

export const Searchbar = ({
  searchText,
  setSearchText,
  placeHolderText = 'Search',
}) => {
  return (
    <Input
      type='search'
      placeholder={placeHolderText}
      aria-label='Search'
      className='rounded-pill search-input'
      onChange={(e) => setSearchText(e.target.value)}
      value={searchText}
    />
  );
};
Searchbar.propTypes = {
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  placeHolderText: PropTypes.string,
};

export default Searchable;
