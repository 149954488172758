import React, { useEffect, useRef, useState } from 'react';
import { Button, CloseButton, Form, Image, Spinner } from 'react-bootstrap';
import { connect, useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  useGetSessionById,
  useUploadImageToS3_Mutation,
} from 'stateManagement/queries/services/aiChat/aiChatQueries';

import { selectActionPlans } from 'stateManagement/redux/actionPlans/selector';
import { clearActionPlanLogs } from 'stateManagement/redux/actionPlans/slice';
import { ACTION_PLAN_STATUSES } from 'stateManagement/redux/actionPlans/types';

import WorkflowDropdown from '../workflows/components/WorkflowDropdown';

const ChatInput = ({
  sessionId,
  input,
  handleInputChange,
  setInput,
  handleSubmit,
  append,
  stop,
  isStreaming,
  authReducer: { user },
}) => {
  const dispatch = useDispatch();
  const { data: chatData } = useGetSessionById(sessionId);
  const [imagesInput, setImagesInput] = useState([]);
  const [images, setImages] = useState([]);
  const textareaRef = useRef(null);

  const resetTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '38px';
    }
  };

  const adjustHeight = () => {
    if (textareaRef.current) {
      const element = textareaRef.current;
      element.style.height = '38px';
      const newHeight = element.scrollHeight;
      element.style.height = `${newHeight}px`;
    }
  };

  useEffect(() => {
    resetTextareaHeight();
  }, []);

  useEffect(() => {
    if (input) {
      adjustHeight();
    }
  }, [input]);

  const actionPlans = useSelector(selectActionPlans);
  const {
    mutate: uploadImage,
    data: uploadedImages,
    isLoading,
  } = useUploadImageToS3_Mutation();

  const scrollToBottom = (smooth = true) => {
    const parentDiv = document.querySelector('#scrollable-panel-content');
    if (parentDiv) {
      parentDiv.style.overflow = 'auto';
      parentDiv.scrollTo({
        top: parentDiv.scrollHeight,
        behavior: smooth ? 'smooth' : 'auto',
      });
    }
  };

  useEffect(() => {
    if (isStreaming) {
      const scrollInterval = setInterval(scrollToBottom, 100);
      return () => clearInterval(scrollInterval);
    }
  }, [isStreaming]);

  useEffect(() => {
    if (uploadedImages?.length > 0) {
      append({
        role: 'user',
        content: [
          { type: 'text', text: input || 'Describe the image in detail.' },
          ...uploadedImages.map((m) => ({ type: 'image', image: m[0] })),
        ],
      });

      let chatActionPlan;
      for (const plan of actionPlans) {
        const { createdAt, chatSessionId, status } = plan;
        if (
          new Date(createdAt) > new Date(chatActionPlan?.createdAt || 0) &&
          chatSessionId === sessionId &&
          status === ACTION_PLAN_STATUSES.COMPLETED
        ) {
          chatActionPlan = plan;
        }
      }
      if (chatActionPlan) {
        dispatch(clearActionPlanLogs({ id: chatActionPlan.id }));
      }
      setInput('');
      resetTextareaHeight();
      setTimeout(scrollToBottom, 100);
    }
  }, [uploadedImages]);

  useEffect(() => {
    let chatActionPlan;
    for (const plan of actionPlans) {
      const { createdAt, chatSessionId, status } = plan;

      if (
        new Date(createdAt) > new Date(chatActionPlan?.createdAt || 0) &&
        chatSessionId === sessionId &&
        status === ACTION_PLAN_STATUSES.COMPLETED
      ) {
        chatActionPlan = plan;
      }
    }

    if (chatActionPlan) {
      const screenshots = chatActionPlan.logs.reduce((acc, log) => {
        if (log.screenshot) {
          acc.push(log.screenshot);
        }
        return acc;
      }, []);

      if (screenshots?.length > 0) {
        setImages(screenshots);
        setImagesInput(screenshots);
        setInput(chatActionPlan.prompt);

        for (let i = 0; i < screenshots.length; i++) {
          fetch(screenshots[i])
            .then((res) => res.blob())
            .then((blob) => {
              setImagesInput((prev) =>
                prev.map((img, index) => (index === i ? blob : img))
              );
            });
        }
      } else {
        setImages([]);
        setImagesInput([]);
        setInput('');
        resetTextareaHeight();
      }
    } else {
      setInput('');
      resetTextareaHeight();
    }
  }, [actionPlans]);

  const handlePaste = (e) => {
    const items = Array.from(e.clipboardData.items);
    const imageFiles = items
      .filter((item) => item.type.startsWith('image/'))
      .map((item) => item.getAsFile());

    if (imageFiles.length > 0) {
      e.preventDefault();
      handleImageFiles(imageFiles);
    }
  };

  const handleImageDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files).filter((file) =>
      file.type.startsWith('image/')
    );
    handleImageFiles(files);
  };

  const handleImageFiles = (files) => {
    setImagesInput((prev) => [...prev, ...files]);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImages((prev) => [...prev, reader.result]);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (input.trim() || imagesInput.length > 0) {
        const form = e.target.closest('form');
        form.dispatchEvent(
          new Event('submit', { cancelable: true, bubbles: true })
        );
      }
    }
  };

  return chatData?.createdBy?._id === user._id ? (
    <div className='position-absolute bottom-0 w-100 pe-5 bg-white py-3 ps-2'>
      {images.length > 0 && (
        <div
          className='mb-2 bg-500 p-2 d-flex align-items-center'
          style={{ overflowX: 'scroll' }}
        >
          <CloseButton
            className='ms-2 me-4'
            onClick={() => {
              setImages([]);
              setImagesInput([]);
            }}
          />
          {images.map((image, index) => (
            <Image
              className='me-2'
              src={image}
              key={`image-input-buffer-${index}`}
              height={50}
              width={50}
            />
          ))}
        </div>
      )}
      {isLoading && (
        <div className='d-flex align-items-center mb-1'>
          <Spinner className='me-2' size='sm' />
          Analyzing data...
        </div>
      )}
      <div>
        <Form
          className='chat-editor-area pt-3'
          style={{ border: 'none' }}
          onSubmit={(e) => {
            e.preventDefault();
            if (imagesInput.length > 0) {
              uploadImage({
                sessionId,
                images: imagesInput,
              });

              setImagesInput([]);
              setImages([]);
            } else {
              handleSubmit(e);
              setInput('');
              resetTextareaHeight();
              setTimeout(scrollToBottom, 100);
            }
          }}
        >
          <Form.Control
            as='textarea'
            ref={textareaRef}
            name='prompt'
            value={input}
            onChange={(e) => {
              handleInputChange(e);
              const element = e.target;
              element.style.height = '38px';
              const newHeight = element.scrollHeight;
              element.style.height = `${newHeight}px`;
            }}
            onKeyDown={handleKeyDown}
            onDrop={handleImageDrop}
            onPaste={handlePaste}
            onDragOver={(e) => e.preventDefault()}
            placeholder='Type your message'
            style={{
              resize: 'none',
              overflow: 'auto',
              padding: '8px 12px',
              lineHeight: '22px',
              minHeight: '38px',
              maxHeight: '200px',
            }}
            autoComplete='off'
          />
          <Form.Group controlId='chatFileUpload'>
            <Form.Label className='chat-file-upload cursor-pointer'>
              <FontAwesomeIcon icon='paperclip' />
            </Form.Label>
            <Form.Control
              type='file'
              className='d-none'
              accept='image/*'
              multiple
              onChange={(e) => handleImageFiles(Array.from(e.target.files))}
            />
          </Form.Group>
          <Button
            variant='falcon-primary'
            size='sm'
            className='me-1'
            type='submit'
          >
            Send
          </Button>
          <WorkflowDropdown />
          {isStreaming && (
            <FontAwesomeIcon
              icon='circle-stop'
              className='mx-2 text-danger cursor-pointer'
              onClick={stop}
              size='2x'
            />
          )}
        </Form>
      </div>
    </div>
  ) : null;
};

export default connect((state) => ({
  authReducer: state.authReducer,
}))(ChatInput);
