import chainAcala from 'blockscope/assets/chains/acala-logo.svg';
import chainAlgorand from 'blockscope/assets/chains/algorand-logo.svg';
import chainAptos from 'blockscope/assets/chains/aptos-logo.svg';
import chainArbitrum from 'blockscope/assets/chains/arbitrum-logo.svg';
import chainArbitrumNova from 'blockscope/assets/chains/arbitrum-nova-logo.svg';
import chainArweave from 'blockscope/assets/chains/arweave-logo.svg';
import chainAstar from 'blockscope/assets/chains/astar-logo.svg';
import chainAudius from 'blockscope/assets/chains/audius-logo.svg';
import chainAurora from 'blockscope/assets/chains/aurora-logo.svg';
import chainAvalanche from 'blockscope/assets/chains/avalanche-avax-logo.svg';
import chainBase from 'blockscope/assets/chains/base-logo.svg';
import chainBitcoinCash from 'blockscope/assets/chains/bitcoin-cash-logo.svg';
import chainBitcoin from 'blockscope/assets/chains/bitcoin-logo.svg';
import chainBitcoinSV from 'blockscope/assets/chains/bitcoin-sv-logo.svg';
import chainBittorrent from 'blockscope/assets/chains/bittorrent-logo.svg';
import chainBlast from 'blockscope/assets/chains/blast-logo.svg';
import chainBoba from 'blockscope/assets/chains/boba-logo.svg';
import chainBsc from 'blockscope/assets/chains/bsc-logo.svg';
import chainCanto from 'blockscope/assets/chains/canto-logo.svg';
import chainCardano from 'blockscope/assets/chains/cardano-logo.svg';
import chainCelo from 'blockscope/assets/chains/celo-logo.svg';
import chainConflux from 'blockscope/assets/chains/conflux-logo.svg';
import chainCorechain from 'blockscope/assets/chains/corechain-logo.svg';
import chainCosmos from 'blockscope/assets/chains/cosmos-logo.svg';
import chainCronos from 'blockscope/assets/chains/cronos-logo.svg';
import chainDash from 'blockscope/assets/chains/dash-logo.svg';
import chainDefiChain from 'blockscope/assets/chains/defichain-logo.svg';
import chainDogechain from 'blockscope/assets/chains/dogecoin-logo.svg';
import chainEos from 'blockscope/assets/chains/eos-logo.svg';
import chainEthereumClassic from 'blockscope/assets/chains/ethereum-classic-logo.svg';
import chainEthereum from 'blockscope/assets/chains/ethereum-eth-logo.svg';
import chainEthereumSepolia from 'blockscope/assets/chains/ethereum-sepolia-logo.svg';
import chainEvmos from 'blockscope/assets/chains/evmos-logo.svg';
import chainFantom from 'blockscope/assets/chains/fantom-ftm-logo.svg';
import chainFilecoin from 'blockscope/assets/chains/filecoin-logo.svg';
import chainFlare from 'blockscope/assets/chains/flare-logo.svg';
import chainFlow from 'blockscope/assets/chains/flow-logo.svg';
import chainFuse from 'blockscope/assets/chains/fuse-logo.svg';
import chainFusion from 'blockscope/assets/chains/fusion-logo.svg';
import chainGnosis from 'blockscope/assets/chains/gnosis-logo.svg';
import chainGodwoken from 'blockscope/assets/chains/godwoken-logo.svg';
import chainHarmony from 'blockscope/assets/chains/harmony-one-logo.svg';
import chainHeco from 'blockscope/assets/chains/heco-logo.svg';
import chainHedera from 'blockscope/assets/chains/hedera-logo.svg';
import chainInjective from 'blockscope/assets/chains/injective-logo.svg';
import chainIotex from 'blockscope/assets/chains/iotex-logo.svg';
import chainKarura from 'blockscope/assets/chains/karura-logo.svg';
import chainKava from 'blockscope/assets/chains/kava-logo.svg';
import chainKcc from 'blockscope/assets/chains/kcc-logo.svg';
import chainKlatyn from 'blockscope/assets/chains/klatyn-logo.svg';
import chainKusama from 'blockscope/assets/chains/kusama-logo.svg';
import chainLinea from 'blockscope/assets/chains/linea-logo.svg';
import chainLitecoin from 'blockscope/assets/chains/litecoin-logo.svg';
import chainLivepeer from 'blockscope/assets/chains/livepeer-logo.svg';
import chainMantle from 'blockscope/assets/chains/mantle-logo.svg';
import chainMetis from 'blockscope/assets/chains/metis-logo.svg';
import chainMilkomeda from 'blockscope/assets/chains/milkomeda-logo.svg';
import chainMina from 'blockscope/assets/chains/mina-logo.svg';
import chainMixin from 'blockscope/assets/chains/mixin-logo.svg';
import chainMoonbeam from 'blockscope/assets/chains/moonbeam-logo.svg';
import chainMoonriver from 'blockscope/assets/chains/moonriver-logo.svg';
import chainMultiversX from 'blockscope/assets/chains/multiversx-logo.svg';
import chainNano from 'blockscope/assets/chains/nano-logo.svg';
import chainNear from 'blockscope/assets/chains/near-logo.svg';
import chainNeo from 'blockscope/assets/chains/neo-logo.svg';
import chainNeutron from 'blockscope/assets/chains/neutron-logo.svg';
import chainOasis from 'blockscope/assets/chains/oasis-logo.svg';
import chainOKT from 'blockscope/assets/chains/okt-logo.svg';
import chainOmnichain from 'blockscope/assets/chains/omnichain-logo.svg';
import chainOptimism from 'blockscope/assets/chains/optimism-ethereum-logo.svg';
import chainOsmosis from 'blockscope/assets/chains/osmosis-logo.svg';
import chainPalm from 'blockscope/assets/chains/palm-logo.svg';
import chainPolkadot from 'blockscope/assets/chains/polkadot-logo.svg';
import chainPolygon from 'blockscope/assets/chains/polygon-matic-logo.svg';
import chainPolgyonZkevm from 'blockscope/assets/chains/polygonzkevm-logo.svg';
import chainPulsechain from 'blockscope/assets/chains/pulsechain-logo.svg';
import chainQuorum from 'blockscope/assets/chains/quorum-logo.svg';
import chainRootstock from 'blockscope/assets/chains/rootstock-logo.svg';
import chainShimmer from 'blockscope/assets/chains/shimmer-logo.svg';
import chainSkale from 'blockscope/assets/chains/skale-logo.svg';
import chainSmartbch from 'blockscope/assets/chains/smartbch-logo.svg';
import chainSolana from 'blockscope/assets/chains/solana-sol-logo.svg';
import chainSoneium from 'blockscope/assets/chains/soneium-logo.svg';
import chainStarknet from 'blockscope/assets/chains/starknet-logo.svg';
import chainSui from 'blockscope/assets/chains/sui-logo.svg';
import chainSyscoin from 'blockscope/assets/chains/syscoin-logo.svg';
import chainTelos from 'blockscope/assets/chains/telos-logo.svg';
import chainTerraClassic from 'blockscope/assets/chains/terra-classic-logo.svg';
import chainTerra from 'blockscope/assets/chains/terra-logo.svg';
import chainTezos from 'blockscope/assets/chains/tezos-logo.svg';
import chainTheGraph from 'blockscope/assets/chains/the-graph-logo.svg';
import chainTheta from 'blockscope/assets/chains/theta-logo.svg';
import chainThor from 'blockscope/assets/chains/thor-logo.svg';
import chainThundercore from 'blockscope/assets/chains/thundercore-logo.svg';
import chainTomochain from 'blockscope/assets/chains/tomochain-logo.svg';
import chainToncoin from 'blockscope/assets/chains/toncoin-logo.svg';
import chainTron from 'blockscope/assets/chains/tron-logo.svg';
import chainVechain from 'blockscope/assets/chains/vechain-logo.svg';
import chainVega from 'blockscope/assets/chains/vega-logo.svg';
import chainVelas from 'blockscope/assets/chains/velas-logo.svg';
import chainWaves from 'blockscope/assets/chains/waves-logo.svg';
import chainXrp from 'blockscope/assets/chains/xrp-logo.svg';
import chainZcash from 'blockscope/assets/chains/zcash-logo.svg';
import chainZilliqa from 'blockscope/assets/chains/zilliqa-logo.svg';
import chainZksyncEra from 'blockscope/assets/chains/zksync-era-logo.svg';

const CHAINS = {
  ACALA: 'acala',
  ALGORAND: 'algorand',
  APTOS: 'aptos',
  ARBITRUM: 'arbitrum',
  ARBITRUM_NOVA: 'arbitrum_nova',
  ARWEAVE: 'arweave',
  ASTAR: 'astar',
  AUDIUS: 'audius',
  AURORA: 'aurora',
  AVALANCHE: 'avalanche',
  AVALANCHE_C: 'avalanche_c',
  BASE: 'base',
  BITCOIN_CASH: 'bitcoin-cash',
  BITCOIN: 'bitcoin',
  BITCOIN_SV: 'bitcoin-sv',
  BITTORRENT: 'bittorrent',
  BLAST: 'blast',
  BOBA: 'boba',
  BSC: 'bsc',
  CANTO: 'canto',
  CARDANO: 'cardano',
  CELO: 'celo',
  CONFLUX: 'conflux',
  CORECHAIN: 'corechain',
  COSMOS: 'cosmos',
  CRONOS: 'cronos',
  DASH: 'dash',
  DEFICHAIN: 'defichain',
  DOGECHAIN: 'dogechain',
  EOS: 'eos',
  ETHEREUM_CLASSIC: 'ethereum-classic',
  ETHEREUM: 'ethereum',
  ETHEREUM_SEPOLIA: 'ethereum-sepolia',
  EVMOS: 'evmos',
  FANTOM: 'fantom',
  FILECOIN: 'filecoin',
  FLARE: 'flare',
  FLOW: 'flow',
  FUSE: 'fuse',
  FUSION: 'fusion',
  GNOSIS: 'gnosis',
  GODWOKEN: 'godwoken',
  HARMONY: 'harmony',
  HECO: 'heco',
  HEDERA: 'hedera',
  INJECTIVE: 'injective',
  IOTEX: 'iotex',
  KARURA: 'karura',
  KAVA: 'kava',
  KCC: 'kcc',
  KLATYN: 'klatyn',
  KUSAMA: 'kusama',
  LINEA: 'linea',
  LITECOIN: 'litecoin',
  LIVEPEER: 'livepeer',
  MANTLE: 'mantle',
  METIS: 'metis',
  MILKOMEDA: 'milkomeda',
  MINA: 'mina',
  MIXIN: 'mixin',
  MOONBEAM: 'moonbeam',
  MOONRIVER: 'moonriver',
  MULTIVERSX: 'multiversx',
  NANO: 'nano',
  NEAR: 'near',
  NEO: 'neo',
  NEUTRON: 'neutron',
  OASIS: 'oasis',
  OKT: 'okt',
  OMNICHAIN: 'omnichain',
  OPTIMISM: 'optimism',
  OSMOSIS: 'osmosis',
  PALM: 'palm',
  POLKADOT: 'polkadot',
  POLYGON: 'polygon',
  POLYGON_ZKEVM: 'polygon_zkevm',
  PULSECHAIN: 'pulsechain',
  QUORUM: 'quorum',
  ROOTSTOCK: 'rootstock',
  SHIMMER: 'shimmer',
  SKALE: 'skale',
  SMARTBCH: 'smartbch',
  SOLANA: 'solana',
  SONEIUM: 'soneium',
  STARKNET: 'starknet',
  SUI: 'sui',
  SYSCOIN: 'syscoin',
  TELOS: 'telos',
  TERRA_CLASSIC: 'terra-classic',
  TERRA: 'terra',
  TEZOS: 'tezos',
  THE_GRAPH: 'the-graph',
  THETA: 'theta',
  THOR: 'thor',
  THUNDERCORE: 'thundercore',
  TOMOCHAIN: 'tomochain',
  TONCOIN: 'toncoin',
  TRON: 'tron',
  VECHAIN: 'vechain',
  VEGA: 'vega',
  VELAS: 'velas',
  WAVES: 'waves',
  XRP: 'xrp',
  ZCASH: 'zcash',
  ZILLIQA: 'zilliqa',
  ZKSYNC_ERA: 'zksync_era',
};

export const LOGOS = {
  [CHAINS.ACALA]: chainAcala,
  [CHAINS.ALGORAND]: chainAlgorand,
  [CHAINS.APTOS]: chainAptos,
  [CHAINS.ARBITRUM]: chainArbitrum,
  [CHAINS.ARBITRUM_NOVA]: chainArbitrumNova,
  [CHAINS.ARWEAVE]: chainArweave,
  [CHAINS.ASTAR]: chainAstar,
  [CHAINS.AUDIUS]: chainAudius,
  [CHAINS.AURORA]: chainAurora,
  [CHAINS.AVALANCHE]: chainAvalanche,
  [CHAINS.AVALANCHE_C]: chainAvalanche,
  [CHAINS.BASE]: chainBase,
  [CHAINS.BITCOIN_CASH]: chainBitcoinCash,
  [CHAINS.BITCOIN]: chainBitcoin,
  [CHAINS.BITCOIN_SV]: chainBitcoinSV,
  [CHAINS.BITTORRENT]: chainBittorrent,
  [CHAINS.BLAST]: chainBlast,
  [CHAINS.BOBA]: chainBoba,
  [CHAINS.BSC]: chainBsc,
  [CHAINS.CANTO]: chainCanto,
  [CHAINS.CARDANO]: chainCardano,
  [CHAINS.CELO]: chainCelo,
  [CHAINS.CONFLUX]: chainConflux,
  [CHAINS.CORECHAIN]: chainCorechain,
  [CHAINS.COSMOS]: chainCosmos,
  [CHAINS.CRONOS]: chainCronos,
  [CHAINS.DASH]: chainDash,
  [CHAINS.DEFICHAIN]: chainDefiChain,
  [CHAINS.DOGECHAIN]: chainDogechain,
  [CHAINS.EOS]: chainEos,
  [CHAINS.ETHEREUM_CLASSIC]: chainEthereumClassic,
  [CHAINS.ETHEREUM]: chainEthereum,
  [CHAINS.ETHEREUM_SEPOLIA]: chainEthereumSepolia,
  [CHAINS.EVMOS]: chainEvmos,
  [CHAINS.FANTOM]: chainFantom,
  [CHAINS.FILECOIN]: chainFilecoin,
  [CHAINS.FLARE]: chainFlare,
  [CHAINS.FLOW]: chainFlow,
  [CHAINS.FUSE]: chainFuse,
  [CHAINS.FUSION]: chainFusion,
  [CHAINS.GNOSIS]: chainGnosis,
  [CHAINS.GODWOKEN]: chainGodwoken,
  [CHAINS.HARMONY]: chainHarmony,
  [CHAINS.HECO]: chainHeco,
  [CHAINS.HEDERA]: chainHedera,
  [CHAINS.INJECTIVE]: chainInjective,
  [CHAINS.IOTEX]: chainIotex,
  [CHAINS.KARURA]: chainKarura,
  [CHAINS.KAVA]: chainKava,
  [CHAINS.KCC]: chainKcc,
  [CHAINS.KLATYN]: chainKlatyn,
  [CHAINS.KUSAMA]: chainKusama,
  [CHAINS.LINEA]: chainLinea,
  [CHAINS.LITECOIN]: chainLitecoin,
  [CHAINS.LIVEPEER]: chainLivepeer,
  [CHAINS.MANTLE]: chainMantle,
  [CHAINS.METIS]: chainMetis,
  [CHAINS.MILKOMEDA]: chainMilkomeda,
  [CHAINS.MINA]: chainMina,
  [CHAINS.MIXIN]: chainMixin,
  [CHAINS.MOONBEAM]: chainMoonbeam,
  [CHAINS.MOONRIVER]: chainMoonriver,
  [CHAINS.MULTIVERSX]: chainMultiversX,
  [CHAINS.NANO]: chainNano,
  [CHAINS.NEAR]: chainNear,
  [CHAINS.NEO]: chainNeo,
  [CHAINS.NEUTRON]: chainNeutron,
  [CHAINS.OASIS]: chainOasis,
  [CHAINS.OKT]: chainOKT,
  [CHAINS.OMNICHAIN]: chainOmnichain,
  [CHAINS.OPTIMISM]: chainOptimism,
  [CHAINS.OSMOSIS]: chainOsmosis,
  [CHAINS.PALM]: chainPalm,
  [CHAINS.POLKADOT]: chainPolkadot,
  [CHAINS.POLYGON]: chainPolygon,
  [CHAINS.POLYGON_ZKEVM]: chainPolgyonZkevm,
  [CHAINS.PULSECHAIN]: chainPulsechain,
  [CHAINS.QUORUM]: chainQuorum,
  [CHAINS.ROOTSTOCK]: chainRootstock,
  [CHAINS.SHIMMER]: chainShimmer,
  [CHAINS.SKALE]: chainSkale,
  [CHAINS.SMARTBCH]: chainSmartbch,
  [CHAINS.SOLANA]: chainSolana,
  [CHAINS.SONEIUM]: chainSoneium,
  [CHAINS.STARKNET]: chainStarknet,
  [CHAINS.SUI]: chainSui,
  [CHAINS.SYSCOIN]: chainSyscoin,
  [CHAINS.TELOS]: chainTelos,
  [CHAINS.TERRA_CLASSIC]: chainTerraClassic,
  [CHAINS.TERRA]: chainTerra,
  [CHAINS.TEZOS]: chainTezos,
  [CHAINS.THE_GRAPH]: chainTheGraph,
  [CHAINS.THETA]: chainTheta,
  [CHAINS.THOR]: chainThor,
  [CHAINS.THUNDERCORE]: chainThundercore,
  [CHAINS.TOMOCHAIN]: chainTomochain,
  [CHAINS.TONCOIN]: chainToncoin,
  [CHAINS.TRON]: chainTron,
  [CHAINS.VECHAIN]: chainVechain,
  [CHAINS.VEGA]: chainVega,
  [CHAINS.VELAS]: chainVelas,
  [CHAINS.WAVES]: chainWaves,
  [CHAINS.XRP]: chainXrp,
  [CHAINS.ZCASH]: chainZcash,
  [CHAINS.ZILLIQA]: chainZilliqa,
  [CHAINS.ZKSYNC_ERA]: chainZksyncEra,
};

export const CHAINS_LIST = Object.values(CHAINS);

export const CHAINS_OPTIONS_LIST = Object.keys(CHAINS).map((key) => {
  return {
    value: CHAINS[key],
    label: CHAINS[key].charAt(0).toUpperCase() + CHAINS[key].slice(1),
  };
});
