import { z } from 'zod';

const sessionRow = z.object({
  sessionId: z.string(),
  name: z.string(),
  createdBy: z.object({
    _id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
  }),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});
export const allSessionsResponse = z.array(sessionRow);

export const sessionResponse = z.object({
  sessionId: z.string(),
  name: z.string(),
  createdBy: z.object({
    _id: z.string(),
    firstName: z.string(),
    lastName: z.string(),
  }),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  messages: z.array(z.any()),
});

/**

 * @typedef {z.infer<typeof sessionRow>} SessionRow
 * @typedef {z.infer<typeof sessionResponse>} Session
 */
