import React from 'react';
import ReactMarkdown from 'react-markdown';

import remarkGfm from 'remark-gfm';

const StreamTextDisplay = ({ text, className = '' }) => {
  const processText = (text) => {
    if (!text) return '';

    return (
      text
        // Standardize numbered list items
        .replace(/^(\d{1,2})\.\s*/gm, '$1. ')
        // Fix bullet point spacing (using circle bullet point for sub-items)
        .replace(/^\s*○\s+/gm, '- ')
        // Handle sub-lists with proper indentation
        .split('\n')
        .map((line) => {
          // Preserve empty lines but clean up excessive spaces
          if (!line.trim()) return '';

          // Handle indented/sub-list items
          if (line.trim().startsWith('- ') && line.startsWith(' ')) {
            return '   ' + line.trim();
          }

          return line.trim();
        })
        .join('\n')
        // Add minimal separator between sections
        .replace(/(?<=\n\d{1,2}\. .*\n)(?=\d{1,2}\.)/g, '\n---\n')
        // Clean up extra newlines but keep minimal spacing
        .replace(/\n{3,}/g, '\n\n')
        .trim()
    );
  };

  const components = {
    p: ({ node, ...props }) => (
      <p className='leading-relaxed my-2' {...props} />
    ),
    ul: ({ node, ...props }) => (
      <ul className='list-disc pl-6 space-y-1' {...props} />
    ),
    ol: ({ node, ...props }) => (
      <ol className='list-decimal pl-6 space-y-2' {...props} />
    ),
    li: ({ node, ordered, ...props }) => (
      <li className='leading-relaxed my-1' {...props} />
    ),
    hr: () => <hr className='my-3 border-t border-gray-200' />,
    code: ({ node, inline, ...props }) => (
      <code
        className={`${
          inline
            ? 'bg-gray-100 px-1.5 py-0.5 rounded text-sm font-mono mx-0.5'
            : 'block bg-gray-100 p-3 rounded my-3'
        }`}
        {...props}
      />
    ),
    strong: ({ node, ...props }) => (
      <strong className='font-semibold' {...props} />
    ),
    em: ({ node, ...props }) => <em className='italic' {...props} />,
  };

  if (!text) return null;

  return (
    <div className={`text-sm ${className}`}>
      <ReactMarkdown remarkPlugins={[remarkGfm]} components={components}>
        {processText(text)}
      </ReactMarkdown>
    </div>
  );
};

export default StreamTextDisplay;

