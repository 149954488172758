import { ADD_OPTION_PROFILER_OPEN_TAB } from '../types';

const initialState = {
  actions: [],
  dataCollected: [],
};
const aiReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_OPTION_PROFILER_OPEN_TAB:
      return {
        ...state,
        actions: [...state.actions, payload],
        isLoading: true,
        error: null,
      };

    default:
      return state;
  }
};

export default aiReducer;

