import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';

import StreamTextDisplay from '../panel/StreamTextDisplay';
import ChatImage from './ChatImage';
import useChatPanelStore from 'stateManagement/store/useChatPanelStore';

import { useGetSessionById } from 'stateManagement/queries/services/aiChat/aiChatQueries';

/**
 * Chat messages component
 *
 * @param {object} props Component props
 * @param {array} props.messages Array of message objects
 * @param {string} props.sessionId ID of the chat session
 * @param {string | null} props.stoppedMessageId ID of the message that was stopped
 * @param {{firstName: string, lastName: string}} props.user User object with firstName and lastName
 */
const ChatMessages = ({
  messages,
  sessionId,
  stoppedMessageId,
  user: { firstName, lastName },
}) => {
  const { data: chatData } = useGetSessionById(sessionId);

  const chatSessionId = useChatPanelStore((state) => state.chatSessionId);

  return chatData?.sessionId === chatSessionId ? (
    <div>
      {messages.length > 0 ? (
        messages.map((message) => (
          <div
            key={message.id}
            className={`d-flex align-items-center ${
              message.role === 'user'
                ? 'justify-content-end'
                : 'justify-content-start'
            } mb-2 `}
          >
            <div>
              <div
                className={` ${
                  message.role === 'user' ? 'text-end' : ''
                } mb-2 fw-bold text-capitalize`}
              >
                {message.role === 'user' ? (
                  `${firstName} ${lastName}: `
                ) : (
                  <span>
                    AI:
                    {message.id === stoppedMessageId && (
                      <span className='ms-2 badge bg-warning text-dark'>
                        Message stopped
                      </span>
                    )}
                  </span>
                )}
              </div>
              <div
                className={`px-3 py-1 m-1 ${
                  message.role === 'user' ? 'bg-primary text-white' : 'bg-200'
                } rounded `}
              >
                {Array.isArray(message.content) ? (
                  <div className={message.role === 'user' ? 'text-end' : ''}>
                    <div>
                      <Row
                        className={`g-2 py-2 ${
                          message.role === 'user' ? 'justify-content-end' : ''
                        }`}
                      >
                        {message.content
                          .filter((m) => m.type === 'image')
                          .map((m) => m.image)
                          .map((img, index) => (
                            <Col xs={6} md={4} key={index}>
                              <ChatImage image={img} />
                            </Col>
                          ))}
                      </Row>
                    </div>

                    {message.content
                      .filter((m) => m.type === 'text')
                      .map((m, index) => (
                        <StreamTextDisplay
                          key={index}
                          text={m.text}
                          className={message.role === 'user' ? 'text-end' : ''}
                        />
                      ))}
                  </div>
                ) : (
                  <StreamTextDisplay
                    text={message.content}
                    className={message.role === 'user' ? 'text-end' : ''}
                  />
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className='text-center text-500 fw-medium p-4'>No Messages</div>
      )}
    </div>
  ) : null;
};

export default ChatMessages;
