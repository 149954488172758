import axios from 'axios';

import { queryClient } from 'stateManagement/queries/client';

import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS,
  SET_AUTH_REDUCER_LOADING,
  SET_AUTH_REDUCER_LOADING_FALSE,
  SET_CONFIRM_EMAIL,
  UPDATE_USER_CONFIGS,
  USER_INVITES_LOADED,
  USER_LOADED,
} from 'stateManagement/redux/types';

import { AUTH_ACTION_TOAST_ID } from 'blockscope/static/toastIds';

import setAuthTokenIntoRequestHeader from 'blockscope/utils/setAuthTokenIntoRequestHeader';
import {
  axiosErrorToast,
  axiosSuccessToast,
  successToast,
} from 'blockscope/utils/toastHandler';

export const loadUser_Action = () => async (dispatch) => {
  dispatch({ type: SET_AUTH_REDUCER_LOADING });
  if (localStorage.token) {
    setAuthTokenIntoRequestHeader(localStorage.token);
    try {
      const res = await axios.get('/api/v2/user/current-user');
      dispatch({
        type: USER_LOADED,
        payload: res.data.dataPayload,
      });
      dispatch(loadUser_Invitations());
    } catch (err) {
      axiosErrorToast(err);
    }
  }

  dispatch({ type: SET_AUTH_REDUCER_LOADING_FALSE });
};

// Register User
export const register_Action =
  (firstName, lastName, email, password, type) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let body = { firstName, lastName, email, password, type };

    try {
      const res = await axios.post('/api/v2/user/create', body, config);

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data.dataPayload,
      });

      successToast(
        'Successfully registered. Check your email for confirmation.',
        AUTH_ACTION_TOAST_ID.REGISTER_SUCCESS
      );
      dispatch(loadUser_Action());
    } catch (err) {
      axiosErrorToast(err, false, AUTH_ACTION_TOAST_ID.ERROR.REGISTER);
      dispatch({
        type: REGISTER_FAIL,
      });
    }
  };

// Login User
export const login_Action =
  (email, password, recaptcha) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      const res = await axios.post(
        '/api/v2/user/login',
        { email, password, recaptcha },
        config
      );

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.dataPayload,
      });

      dispatch(loadUser_Action());
    } catch (err) {
      axiosErrorToast(err, AUTH_ACTION_TOAST_ID.ERROR.LOGIN);
      dispatch({
        type: LOGIN_FAIL,
      });
    }
  };

// Logout
export const logout_Action = () => async (dispatch) => {
  queryClient.clear();

  await axios.get('/api/v2/user/logout');

  dispatch({ type: LOGOUT });
};

// Confirm Email
export const confirmEmail_Action =
  (email, password, confirmToken) => async (dispatch) => {
    dispatch({
      type: SET_CONFIRM_EMAIL,
      payload: { confirmEmail: false },
    });
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = { email, password, confirmToken };

    try {
      const res = await axios.put('/api/v2/user/confirm', body, config);
      axiosSuccessToast(res, AUTH_ACTION_TOAST_ID.SUCCESS.CONFIRM_EMAIL);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data.dataPayload,
      });

      dispatch(loadUser_Action());
    } catch (err) {
      axiosErrorToast(err, AUTH_ACTION_TOAST_ID.ERROR.CONFIRM_EMAIL);
    }
  };

// Resend Email Verification link
export const resendEmailConfirmationLink = (email) => async (dispatch) => {
  if (localStorage.token) {
    setAuthTokenIntoRequestHeader(localStorage.token);
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const body = { email };
  try {
    const res = await axios.put(
      '/api/v2/user/send-confirmation-email',
      body,
      config
    );
    axiosSuccessToast(res, AUTH_ACTION_TOAST_ID.SUCCESS.RESEND_CONFIRM_EMAIL);
  } catch (err) {
    axiosErrorToast(err, AUTH_ACTION_TOAST_ID.ERROR.RESEND_CONFIRM_EMAIL);
  }
};

// Resend Email Verification link
export const sendForgotPasswordEmail = (email) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = { email };

  try {
    const res = await axios.put('/api/v2/user/forgot-password', body, config);
    axiosSuccessToast(res, AUTH_ACTION_TOAST_ID.SUCCESS.SEND_FORGOT_PASSWORD);
  } catch (err) {
    axiosErrorToast(err, AUTH_ACTION_TOAST_ID.ERROR.SEND_FORGOT_PASSWORD);
  }
};

export const passwordResetSubmission =
  (password, email, resetToken) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = { password, email, resetToken };
    try {
      const res = await axios.put('/api/v2//user/reset-password', body, config);
      axiosSuccessToast(res, AUTH_ACTION_TOAST_ID.SUCCESS.PASSWORD_RESET);
    } catch (err) {
      axiosErrorToast(err, AUTH_ACTION_TOAST_ID.ERROR.PASSWORD_RESET);
    }
  };

export const changePasswordAction =
  (password, newPassword) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const body = { password, newPassword };

    try {
      const res = await axios.put(`/api/v2/user/change-password`, body, config);
      axiosSuccessToast(res, AUTH_ACTION_TOAST_ID.SUCCESS.CHANGE_PASSWORD);
    } catch (err) {
      axiosErrorToast(err, AUTH_ACTION_TOAST_ID.ERROR.CHANGE_PASSWORD);
    }
  };

export const loadUser_Invitations = () => async (dispatch) => {
  dispatch({ type: SET_AUTH_REDUCER_LOADING });
  if (localStorage.token) {
    setAuthTokenIntoRequestHeader(localStorage.token);
    try {
      const res = await axios.get('/api/v2/user/invite/view-invites');
      dispatch({
        type: USER_INVITES_LOADED,
        payload: res.data.dataPayload,
      });
    } catch (err) {
      axiosErrorToast(err);
    }
  }

  dispatch({ type: SET_AUTH_REDUCER_LOADING_FALSE });
};

export const updateAddressViewOptionsAction =
  (displayTronAddress) => async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const payload = { displayTronAddress };

    try {
      const res = await axios.put(
        `/api/v2/user/update-config`,
        payload,
        config
      );

      dispatch({ type: UPDATE_USER_CONFIGS, payload });
      axiosSuccessToast(res, AUTH_ACTION_TOAST_ID.SUCCESS.UPDATE_ADDRESS_VIEW);
    } catch (err) {
      axiosErrorToast(err, AUTH_ACTION_TOAST_ID.ERROR.UPDATE_ADDRESS_VIEW);
    }
  };

