import React, { useState } from 'react';
import { Col, Image, Modal, Row } from 'react-bootstrap';

import StreamTextDisplay from '../panel/StreamTextDisplay';
import useChatPanelStore from 'stateManagement/store/useChatPanelStore';

import { useGetSessionById } from 'stateManagement/queries/services/aiChat/aiChatQueries';

/**
 * Chat messages component
 *
 * @param {object} props Component props
 * @param {array} props.messages Array of message objects
 * @param {string} props.sessionId ID of the chat session
 * @param {string | null} props.stoppedMessageId ID of the message that was stopped
 * @param {{firstName: string, lastName: string}} props.user User object with firstName and lastName
 */
const ChatImage = ({ image }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Image
        // fluid
        rounded
        className='mb-2 cursor-pointer'
        src={image}
        alt=''
        height={100}
        width={100}
        onClick={() => setShow(!show)}
      />
      <Modal show={show} onHide={() => setShow(false)} size='xl'>
        <Modal.Body>
          <Image fluid src={image} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChatImage;
