import actionPlansReducer from './actionPlans/slice';
import componentTrackerReducer from './reducers/componentTrackerReducer';
import { combineReducers } from 'redux';

import abiGuesserReducer from 'stateManagement/redux/reducers/abiGuesserReducer';
import aiReducer from 'stateManagement/redux/reducers/aiReducer';
import authReducer from 'stateManagement/redux/reducers/authReducer';
import deliverySettingsReducer from 'stateManagement/redux/reducers/deliverySettingsReducer';
import orgReducer from 'stateManagement/redux/reducers/orgReducer';

export default combineReducers({
  authReducer,
  orgReducer,
  deliverySettingsReducer,
  ai: aiReducer,
  abiGuesserReducer,
  componentTracker: componentTrackerReducer,
  actionPlans: actionPlansReducer,
});

