import { create } from 'zustand';

const initState = {
  showChatPanel: false,
  panelPage: null,
  chatSessionId: null,
};

const useChatPanelStore = create((set) => ({
  ...initState,

  setShowChatPanel: (showChatPanel) => set(() => ({ showChatPanel })),
  setPanelPage: (panelPage) => set(() => ({ panelPage })),
  setChatSessionId: (chatSessionId) => set(() => ({ chatSessionId })),
}));

export default useChatPanelStore;
