import React from 'react';
import { Card } from 'react-bootstrap';

import Flex from 'blockscope/components/common/Flex';

/**
 * @typedef {Object} HeaderProps
 * @property {string} title - The title to be displayed in the header.
 * @property {string} [description] - The optional description to be displayed in the header.
 */

/**
 * TitleBar component renders a card with a header, optional start and end elements,
 * and an expandable section for examples.
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} [props.startEl] - The element to be displayed at the start of the header.
 * @param {React.ReactNode} [props.endEl] - The element to be displayed at the end of the header.
 * @param {HeaderProps} props.header - The header object containing title and description.
 * @param {boolean} [props.singleRow=true] - Flag to determine if the header description should be displayed in a single row.
 * @param {React.ReactNode} [props.children] - The children elements to be rendered inside the card body.
 *
 * @returns {JSX.Element} The rendered TitleBar component.
 */
function TitleBar({ startEl, endEl, header, singleRow = true, children }) {
  return (
    <Card className='flex-fill mb-3'>
      <Card.Body>
        <Flex justifyContent={'between'} alignItems={'center'}>
          <Flex alignItems={'center'}>
            <div className={startEl ? 'me-3' : ''}>{startEl}</div>
            <h4 className='mb-0'>{header.title}</h4>
            {singleRow && header.description && (
              <div className='mt-1 fs--1 ms-3'>{header.description}</div>
            )}
          </Flex>
          {endEl}
        </Flex>
        {!singleRow && header.description && (
          <div className='mt-2 fs--1'>{header.description}</div>
        )}
        {children}
      </Card.Body>
    </Card>
  );
}

export default TitleBar;
