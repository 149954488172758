// Auth Types
export const USER_LOADED = 'USER_LOADED';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const SET_AUTH_REDUCER_LOADING = 'SET_AUTH_REDUCER_LOADING';
export const SET_AUTH_REDUCER_LOADING_FALSE = 'SET_AUTH_REDUCER_LOADING_FALSE';
export const SET_CONFIRM_EMAIL = 'SET_CONFIRM_EMAIL';
export const USER_INVITES_LOADED = 'USER_INVITES_LOADED';
export const UPDATE_USER_CONFIGS = 'UPDATE_USER_CONFIGS ';

// org
export const GET_ORG_DETAILS = 'GET_ORG_DETAILS';
export const SET_ORG_DETAILS_LOADER = 'SET_ORG_DETAILS_LOADER';
export const GET_ORG_PIPELINES = 'GET_ORG_PIPELINES';
export const SET_ORG_PIPELINES_LOADER = 'SET_ORG_PIPELINES_LOADER';
export const GET_ORG_WATCHTOWERS = 'GET_ORG_WATCHTOWERS';
export const SET_ORG_WATCHTOWERS_LOADER = 'SET_ORG_WATCHTOWERS_LOADER';
export const GET_ORG_INVITED_USERS = 'GET_ORG_INVITED_USERS';
export const SET_ORG_INVITED_USERS_LOADER = 'SET_ORG_INVITED_USERS_LOADER';
export const SWITCH_ORG = 'SWITCH_ORG';

// delivery targets
export const SET_DELIVERY_TARGET_LOADER = 'SET_DELIVERY_TARGET_LOADER';
export const GET_DELIVERY_TARGET_SETTINGS = 'GET_DELIVERY_TARGET_SETTINGS';
export const GET_SINGLE_DELIVERY_TARGET_SETTING =
  'GET_SINGLE_DELIVERY_TARGET_SETTING';
export const SET_CREATE_DELIVERY_TARGET_LOADER =
  'SET_CREATE_DELIVERY_TARGET_LOADER';
export const SET_DELETE_DELIVERY_TARGET_LOADER =
  'SET_DELETE_DELIVERY_TARGET_LOADER';
export const SET_SINGLE_DELIVERY_TARGET_LOADER =
  'SET_SINGLE_DELIVERY_TARGET_LOADER';

// abi guesser
export const GUESS_ABI = 'GUESS_ABI';
export const SET_GUESS_ABI_LOADER = 'SET_GUESS_ABI_LOADER';

// ai helper
export const ADD_AI_OPTION = 'ADD_AI_OPTION';
export const REMOVE_AI_OPTION = 'REMOVE_AI_OPTION';

// profiler ai actions
export const ADD_OPTION_PROFILER_OPEN_TAB = 'ADD_OPTION_PROFILER_OPEN_TAB';
export const REMOVE_OPTION_PROFILER_OPEN_TAB =
  'REMOVE_OPTION_PROFILER_OPEN_TAB';

