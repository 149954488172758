// reducers/componentTrackerReducer.js
import {
  ADD_COMPONENT,
  REMOVE_COMPONENT,
} from '../actions/componentTrackerActions';

const initialState = {
  activeComponents: [], // List of active component IDs
};

const componentTrackerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COMPONENT:
      return {
        ...state,
        activeComponents: [...state.activeComponents, action.payload],
      };
    case REMOVE_COMPONENT:
      return {
        ...state,
        activeComponents: state.activeComponents.filter(
          (id) => id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default componentTrackerReducer;

