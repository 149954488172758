import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCodepen,
  faFacebook,
  faFacebookF,
  faFacebookSquare,
  faGooglePlusG,
  faGulp,
  faHotjar,
  faHtml5,
  faInstagram,
  faJs,
  faLinkedin,
  faLinkedinIn,
  faMediumM,
  faNodeJs,
  faPinterest,
  faSass,
  faStripe,
  faStripeS,
  faTrello,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faCheckCircle as farCheckCircle,
  faCircle as farCircle,
  faClock as farClock,
  faComment as farComment,
  faCopy as farCopy,
  faDizzy as farDizzy,
  faEdit as farEdit,
  faFile as farFile,
  faFileArchive as farFileArchive,
  faFileAudio as farFileAudio,
  faFileCode as farFileCode,
  faFileImage as farFileImage,
  faFilePdf as farFilePdf,
  faFileVideo as farFileVideo,
  faHeart as farHeart,
  faImage as farImage,
  faLaughBeam as farLaughBeam,
  faLightbulb as farLightbulb,
  faObjectGroup as farObjectGroup,
  faObjectUngroup as farObjectUngroup,
  faPaperPlane as farPaperPlane,
  faQuestionCircle as farQuestionCircle,
  faSmileBeam as farSmileBeam,
  faStar as farStar,
} from '@fortawesome/free-regular-svg-icons';
import {
  faAddressBook,
  faAddressCard,
  faAlignLeft,
  faAlignRight,
  faAngleLeft,
  faAngleRight,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowUp,
  faArrowsAltV,
  faArrowsLeftRight,
  faArrowsRotate,
  faAt,
  faAward,
  faBalanceScaleLeft,
  faBan,
  faBell,
  faBlender,
  faBolt,
  faBook,
  faBookOpen,
  faBrain,
  faBridge,
  faBroadcastTower,
  faBroom,
  faBrush,
  faBug,
  faBuildingColumns,
  faCalendar,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faCamera,
  faCameraRetro,
  faCapsules,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faCartPlus,
  faCashRegister,
  faChalkboardTeacher,
  faChartLine,
  faChartPie,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faCheckSquare,
  faChessRook,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleNotch,
  faCircleStop,
  faCircleXmark,
  faClock,
  faClosedCaptioning,
  faCloudDownloadAlt,
  faCode,
  faCodeBranch,
  faCog,
  faCoins,
  faComment,
  faCommentDollar,
  faComments,
  faCopy,
  faCross,
  faCrown,
  faCube,
  faCubes,
  faDatabase,
  faDesktop,
  faDollarSign,
  faDownload,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faEnvelopeOpen,
  faEnvelopeOpenText,
  faExchangeAlt,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faExpand,
  faExpandArrowsAlt,
  faExternalLinkAlt,
  faEye,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileContract,
  faFileDownload,
  faFileImport,
  faFileInvoiceDollar,
  faFilePdf,
  faFileUpload,
  faFilter,
  faFire,
  faFlag,
  faFloppyDisk,
  faFolderPlus,
  faGear,
  faGift,
  faGlobe,
  faGlobeAmericas,
  faGraduationCap,
  faGripLines,
  faHandHoldingWater,
  faHashtag,
  faHeadphones,
  faHeart,
  faHexagonNodes,
  faHome,
  faIcons,
  faImage,
  faInfinity,
  faInfo,
  faInfoCircle,
  faKey,
  faLaptopCode,
  faLayerGroup,
  faLink,
  faList,
  faListAlt,
  faListUl,
  faLocationArrow,
  faLock,
  faLockOpen,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faMagnifyingGlassArrowRight,
  faMap,
  faMapMarkerAlt,
  faMapPin,
  faMessage,
  faMinus,
  faMobile,
  faMoon,
  faMusic,
  faObjectUngroup,
  faPalette,
  faPaperPlane,
  faPaperclip,
  faPause,
  faPen,
  faPenNib,
  faPencilAlt,
  faPeopleArrows,
  faPercentage,
  faPersonRays,
  faPhone,
  faPhoneAlt,
  faPlay,
  faPlayCircle,
  faPlug,
  faPlus,
  faPlusSquare,
  faPoll,
  faPrint,
  faProjectDiagram,
  faPuzzlePiece,
  faQuestionCircle,
  faRedo,
  faRedoAlt,
  faReply,
  faRocket,
  faSackDollar,
  faSearch,
  faSearchDollar,
  faSearchPlus,
  faShapes,
  faShare,
  faShareAlt,
  faShieldAlt,
  faShieldHalved,
  faShoppingCart,
  faShuffle,
  faSignInAlt,
  faSignOutAlt,
  faSlidersH,
  faSmileBeam,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortAmountDown,
  faSortAmountUp,
  faSortNumericDown,
  faSortNumericUp,
  faSpinner,
  faSquarePlus,
  faStar,
  faStarHalfAlt,
  faStop,
  faStore,
  faStream,
  faSun,
  faSyncAlt,
  faTable,
  faTag,
  faTags,
  faTasks,
  faTerminal,
  faTh,
  faThumbsUp,
  faThumbtack,
  faTicketAlt,
  faTimes,
  faTimesCircle,
  faTint,
  faToolbox,
  faTools,
  faTowerCell,
  faTowerObservation,
  faTrash,
  faTrashAlt,
  faTrophy,
  faUniversity,
  faUnlockAlt,
  faUpload,
  faUser,
  faUserCircle,
  faUserCog,
  faUserFriends,
  faUserGraduate,
  faUserPlus,
  faUserSecret,
  faUserTag,
  faUsers,
  faUtensils,
  faVideo,
  faWallet,
  faWandMagicSparkles,
  faWarehouse,
  faWrench,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

import { blockscopeDefaultTokenFA } from 'blockscope/assets/blockscopeCustomFA';

library.add(
  faCircleXmark,
  // custom
  faCalendar,
  faBlender,
  faMessage,
  faCoins,
  faFloppyDisk,
  faFileContract,
  faPeopleArrows,
  faWallet,
  faCubes,
  faLaptopCode,
  faAddressBook,
  faAddressCard,
  faDesktop,
  faCube,
  faWarehouse,
  faShuffle,
  faDatabase,
  faSearchDollar,
  faXmark,

  // Solid
  faBrain,
  faHexagonNodes,
  faArrowRightArrowLeft,
  faGear,
  faSquarePlus,
  faShieldHalved,
  faTowerCell,
  faSearchPlus,
  faEnvelopeOpenText,
  faFileDownload,
  faObjectUngroup,
  faPhoneAlt,
  faKey,
  faFileImport,
  faTicketAlt,
  faChartPie,
  faCopy,
  faChessRook,
  faSearch,
  faHome,
  faBell,
  faPlay,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faExchangeAlt,
  faAngleRight,
  faAngleLeft,
  faEllipsisH,
  faCrown,
  faListAlt,
  faCheck,
  faBan,
  faStream,
  faArrowsAltV,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSortAmountDown,
  faSortAmountUp,
  faCheckCircle,
  faUserCircle,
  faQuestionCircle,
  faLock,
  faCircle,
  faHeart,
  faShareAlt,
  faCaretRight,
  faArrowDown,
  faArrowUp,
  faArrowLeft,
  faArrowRight,
  faPrint,
  faDollarSign,
  faLink,
  faPlus,
  faMinus,
  faUser,
  faPencilAlt,
  faCamera,
  faTimesCircle,
  faSignInAlt,
  faBook,
  faPuzzlePiece,
  faTrash,
  faAlignLeft,
  faAlignRight,
  faPlug,
  faTimes,
  faFilter,
  faExternalLinkAlt,
  faCartPlus,
  faShoppingCart,
  faStar,
  faStarHalfAlt,
  faArchive,
  faEnvelope,
  faClock,
  faStar,
  faReply,
  faLocationArrow,
  faCog,
  faPaperclip,
  faImage,
  faEllipsisV,
  faEnvelopeOpen,
  faRedo,
  faSlidersH,
  faTrashAlt,
  faTerminal,
  faFileArchive,
  faFilePdf,
  faFileAlt,
  faEye,
  faCaretUp,
  faCodeBranch,
  faExclamationTriangle,
  faListUl,
  faTh,
  faGift,
  faUsers,
  faGlobeAmericas,
  faGlobe,
  faPlusSquare,
  faCaretDown,
  faUserPlus,
  faCircleNotch,
  faExclamation,
  faThumbsUp,
  faComments,
  faPalette,
  faEdit,
  faTrashAlt,
  faShare,
  faCheckSquare,
  faTools,
  faCheckDouble,
  faSmileBeam,
  faPoll,
  faPercentage,
  faTags,
  faIcons,
  faList,
  faUserFriends,
  faExclamationCircle,
  faFolderPlus,
  faTag,
  faAt,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarDay,
  faMapMarkerAlt,
  faCross,
  faFlag,
  faThumbtack,
  faTable,
  faChartLine,
  faShapes,
  faMap,
  faFire,
  faLayerGroup,
  faRocket,
  faWrench,
  faSun,
  faMoon,
  faInfoCircle,
  faSpinner,
  faCapsules,
  faTasks,
  faCloudDownloadAlt,
  faSyncAlt,
  faBug,
  faProjectDiagram,
  faBolt,
  faPaperPlane,
  faLongArrowAltDown,
  faLongArrowAltUp,
  faInfinity,
  faFile,
  faMusic,
  faMobile,
  faBookOpen,
  faTint,
  faLockOpen,
  faBroadcastTower,
  faShieldAlt,
  faUserSecret,
  faStore,
  faBuildingColumns,
  faWandMagicSparkles,

  // Brand
  faFacebook,
  faFacebookF,
  faFacebookSquare,
  faLinkedin,
  faInstagram,
  faPinterest,
  faGulp,
  faNodeJs,
  faSass,
  faTwitter,
  faGooglePlusG,
  faLinkedinIn,
  faMediumM,
  faHotjar,
  faYoutube,
  faVideo,
  faInfo,
  faPhone,
  faTrello,
  faCode,
  faJs,
  faHtml5,
  faTrophy,
  faCodepen,

  // Regular
  farHeart,
  farLightbulb,
  farObjectUngroup,
  farPaperPlane,
  farStar,
  farImage,
  farFileArchive,
  farFilePdf,
  farFile,
  farFileVideo,
  farFileAudio,
  farFileImage,
  farFileCode,
  farClock,
  farQuestionCircle,
  farDizzy,
  farLaughBeam,
  farSmileBeam,
  farCircle,
  farCheckCircle,
  farCopy,
  farComment,
  farEdit,
  faRedoAlt,
  faUserGraduate,
  faChalkboardTeacher,
  faGraduationCap,
  faSignOutAlt,
  faDownload,
  faFileUpload,
  faExpand,
  faExpandArrowsAlt,
  faUnlockAlt,
  faGripLines,
  faComment,
  faHeadphones,
  faClosedCaptioning,
  faMapPin,
  faPlayCircle,
  faPenNib,
  faAward,
  faBrush,
  faHashtag,
  faFileInvoiceDollar,
  faBalanceScaleLeft,
  faCommentDollar,
  faHandHoldingWater,
  faCameraRetro,
  faUtensils,
  faUniversity,
  faUserTag,
  faPause,
  faBridge,
  faArrowsRotate,
  faPersonRays,
  faTowerObservation,
  farObjectGroup,
  faArrowsLeftRight,
  faCashRegister,
  faToolbox,
  faStop,
  faBroom,
  faMagnifyingGlassArrowRight,
  faPen,
  faStripe,
  faStripeS,
  faUserCog,
  faUpload,
  faSackDollar,
  faBrain,
  faCircleStop
);

// ADDING CUSTOM BLOCKSCOPE ICONS
library.add(blockscopeDefaultTokenFA);

