import React, { useState } from 'react';
import { Button, Card, Col, Image, Row, Spinner } from 'react-bootstrap';

import StreamTextDisplay from '../panel/StreamTextDisplay';
import ChatInput from './ChatInput';
import ChatMessages from './ChatMessages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useChat } from 'ai/react';
import useChatPanelStore from 'stateManagement/store/useChatPanelStore';

import { useGetSessionById } from 'stateManagement/queries/services/aiChat/aiChatQueries';

import FalconCardHeader from 'blockscope/components/common/FalconCardHeader';

const ChatWindow = ({ sessionId }) => {
  const { data: chatData } = useGetSessionById(sessionId);
  const setChatSessionId = useChatPanelStore((state) => state.setChatSessionId);
  const chatSessionId = useChatPanelStore((state) => state.chatSessionId);
  const [isStreaming, setIsStreaming] = useState(false);
  const [currentStreamingId, setCurrentStreamingId] = useState(null);
  const [stoppedMessageId, setStoppedMessageId] = useState(null);

  const {
    messages,
    setMessages,
    input,
    setInput,
    stop,
    handleInputChange,
    handleSubmit,
    append,
    isLoading,
  } = useChat({
    id: sessionId,
    streamProtocol: 'text',
    api: `${process.env.REACT_APP_BACKEND_URL}/api/v2/ai-chat/message/${sessionId}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    onResponse: (response) => {
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let result = '';

      setMessages((prevMessages) => {
        const newMessage = {
          id: `msg-${Date.now()}`, // Ensure we have an id
          role: 'assistant',
          content: [{ type: 'text', text: result }],
        };
        setCurrentStreamingId(newMessage.id);
        return [...prevMessages, newMessage];
      });

      const readStream = async () => {
        try {
          const { done, value } = await reader.read();
          if (done) {
            setIsStreaming(false);
            return;
          }
          setIsStreaming(true);
          result += decoder.decode(value, { stream: true });
          setMessages((prevMessages) =>
            prevMessages.map((m, i) =>
              i === prevMessages.length - 1 ? { ...m, content: result } : m
            )
          );
          readStream();
        } catch (error) {
          if (error.name === 'AbortError') {
            setIsStreaming(false);
            console.log('Stream was aborted');
          } else {
            console.error('Error reading stream:', error);
          }
        }
      };

      readStream();
    },
  });

  const handleStopClick = async () => {
    try {
      await stop();
      // Only set the stopped message ID if we were actually streaming this message
      if (currentStreamingId) {
        setStoppedMessageId(currentStreamingId);
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Stream stopped successfully');
      } else {
        console.error('Error stopping stream:', error);
      }
    } finally {
      setIsStreaming(false);
      setCurrentStreamingId(null);
    }
  };

  return chatData?.sessionId === chatSessionId ? (
    <Card>
      <FalconCardHeader title={chatData?.name} light />
      <Card.Body>
        <ChatMessages
          messages={messages}
          sessionId={sessionId}
          stoppedMessageId={stoppedMessageId}
          user={chatData?.createdBy}
        />
        {isLoading && (
          <div className='d-flex align-items-center'>
            <Spinner className='me-2' />
            Thinking about it...
          </div>
        )}

        <ChatInput
          sessionId={sessionId}
          input={input}
          setInput={setInput}
          handleInputChange={handleInputChange}
          handleSubmit={handleSubmit}
          append={append}
          stop={handleStopClick}
          isStreaming={isStreaming}
        />
      </Card.Body>
    </Card>
  ) : null;
};

export default ChatWindow;
