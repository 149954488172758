export const PAGES = {
  DASHBOARD: {
    title: 'My Dashboard',
    description: 'dashboard for all Blockscope activities',
  },
  ALL_SERVICES: {
    title: 'All Services',
    description: 'List of all available services',
  },
  ORG_SETTINGS: {
    title: 'Workspace Settings',
    description: 'Settings for your workspace',
  },
  USER_SETTINGS: {
    title: 'User Settings',
    description: 'Settings for your account',
  },
  NOTIFICATIONS: {
    title: 'Notifications',
    description: 'List of all notifications',
  },
  ADMIN_CONTROL: {
    title: 'Admin Control',
    description: 'admin control for all Blockscope activities and orgs',
  },

  SELECT_ORG: {
    title: 'Select Workspace',
    description: 'Select a workspace to get started',
  },
  AUTH: { title: 'Authenticate', description: 'Authenticate with Blockscope' },
  LOGIN: { title: 'Login', description: 'Login page' },
  FORGOT_PASSWORD: {
    title: 'Forgot Password',
    description: 'Forgot Password',
  },
  RESET_PASSWORD: {
    title: 'Reset Password',
    description: 'Reset Password',
  },
  REGISTER: { title: 'Register', description: 'Register for Blockscope' },
  CONFIRM_EMAIL: { title: 'Confirm Email', description: 'Confirm Email' },
  ACCEPT_INVITE: { title: 'Accept Invite', description: 'Accept Invite' },
  ERROR: { title: 'Error', description: 'Error' },
  CONTACT_US: { title: 'Contact Us', description: 'Contact Us' },
  PRICING: { title: 'Pricing', description: 'Pricing' },
  CALENDLY: { title: 'Calendly', description: 'Calendly' },

  // *************************************************
  // Solutions Pages
  // *************************************************

  DOCS_API_DECODER_TRANSACTION: {
    title: 'Decode Transaction - API Docs',
    description:
      'Get all the relevant decoded information about a transaction from a single TXN hash.',
  },
  DOCS_API_LABELS_ADDRESSES: {
    title: 'Get Address Labels - API Docs',
    description: 'Get all labels from a wallet address.',
  },
  DOCS_API_RISK_ADDRESSES: {
    title: 'Get Address Risk - API Docs',
    description: 'Get security labels and risk scores from an address.',
  },

  // *************************************************
  // Solutions Pages
  // *************************************************

  LANDING_SOLUTIONS: { title: 'Solutions' },
  LANDING_DEVKIT: { title: 'Devkit' },
  LANDING_DEFENDER: { title: 'Defender' },
  LANDING_MARKETER: { title: 'Marketer' },
  LANDING_STUDIO: { title: 'Studio' },

  // *************************************************
  // Product Pages
  // *************************************************

  LANDING_PRODUCTS: { title: 'Products', description: 'List of all products' },
  LANDING_WATCHTOWER: {
    title: 'Watchtower',
    description: 'Watchtower product for monitoring on-chain activity',
  },
  LANDING_CONTRACT_USAGE: {
    title: 'Contract Usage',
    description: 'Contract Usage product for verifying contract usage',
  },
  LANDING_BLOCK_BREAKDOWN: {
    title: 'Block Breakdown',
    description: 'Block Breakdown tool for viewing details of a single block',
  },
  LANDING_TRACER: {
    title: 'Tracer Product',
    description: 'Tracer product for viewing fund flow activity across chain',
  },
  LANDING_TRANSACTION_DECODER: {
    title: 'Transaction Decoder',
    description:
      'Transaction Decoder product for viewing details of a single transaction',
  },
  LANDING_WALLET_PROFILER: {
    title: 'Wallet Profiler',
    description:
      'Wallet Profiler product for viewing details of a single wallet, such as total assets and recent activity',
  },
  LANDING_PIPELINES: {
    title: 'Pipelines',
    description: 'Pipelines product for monitoring on-chain activity',
  },
  LANDING_SECURITY_MONITORING: {
    title: 'Contract Gaurd',
    description:
      'Contract Gaurd product for tracking interactions with a list of monitored addresses',
  },
  LANDING_WEB_DIRECTORY: {
    title: 'Web Directory',
    description:
      'Web Directory product for viewing entities and actors in the Web3 space',
  },
  LANDING_INVESTIGATOR: {
    title: 'Investigator',
    description:
      'Investigator product for tracking and investigating on-chain activity',
  },
  LANDING_APIS: {
    title: 'APIs',
    description: 'List of all Blockscope APIs available for developers',
  },

  // *************************************************
  // Data Capture Services
  // *************************************************
  WATCHTOWER_CREATE: {
    title: 'Create Watchtower',
    description: 'Create watchtower for data capture',
  },
  WATCHTOWER_LIST: {
    title: 'Your Watchtowers',
    description: 'Manage all your active and inactive watchtowers',
  },
  PUBLIC_WATCHTOWER_LIST: {
    title: 'Community Watchtowers',
    description:
      'Browse what other users are monitoring via publicly available watchtowers',
  },
  WATCHTOWER_MAIN_PAGE: {
    title: 'Watchtowers',
    description: 'Manage all your active and inactive watchtowers',
  },
  PILELINE_MAIN_PAGE: {
    title: 'Pipeline Dashboard',
    description: 'Manage all your active and inactive pipelines',
  },
  WATCHTOWER_DETAILS: {
    title: 'Watchtower Details',
    description:
      'Details of a single watchtower, including its records and capture analytics',
  },
  WATCHTOWER_RECORDS: {
    title: 'Watchtower Records',
    description: 'captured watchtower records',
  },
  PIPELINE_CREATE: {
    title: 'Create Pipeline',
    description: 'Create pipeline for data capture',
  },
  PIPELINE_LIST: {
    title: 'Your Pipelines',
    description: 'Manage all your active and inactive pipelines',
  },
  PIPELINE_DETAILS: {
    title: 'Pipeline Details',
    description: 'Details of a single pipeline, including its captured records',
  },
  DELIVERY_SETTINGS: {
    title: 'Data Delivery Settings',
    description: 'Manage all your connections for data delivery pipelines here',
  },

  // *************************************************
  // Chain Exploration Services
  // *************************************************
  TOKENFLOW: {
    title: 'Token Flow',
    description: 'Track flow of tokens through a single wallet',
  },
  TRANSACTION_DECODER: {
    title: 'Transaction Decoder',
    description:
      'All actions involved in a single transaction, decoded & readable',
  },
  TRANSACTION_SIMULATOR: {
    title: 'Transaction Simulator',
    description:
      'Simulate a transaction on a single chain, with no actual transactions being sent',
  },
  WALLET_PROFILE: {
    title: 'Wallet Profile',
    description: 'Summary and recent activity for a single wallet',
  },
  BLOCK_BREAKDOWN: {
    title: 'Block Breakdown',
    description: 'full breakdown of a single block on a chain',
  },
  CONTRACT_USAGE: {
    title: 'Contract Usage',
    description: 'Usage data for a single contract',
  },
  CONTRACT_BREAKDOWN: {
    title: 'Contract Analysis',
    description: 'Dig into the technical details of a single contract',
  },
  MAIN_SEARCH: {
    title: 'Search',
    description:
      'Search transactions, blocks, addresses from the chain, decoded & readable',
  },
  ENTITY_INTERACTION: {
    title: 'Entity Interaction',
    description: 'See all interactions between two entities on the chain',
  },
  ADVANCED_SEARCH: {
    title: 'Advanced Search',
    description: 'Advanced interaction search on the chain',
  },

  // chain streams
  EVENT_STREAM: {
    title: 'Event Stream',
    description:
      'See the most recent Events from the chain, decoded & readable',
  },
  TRACE_STREAM: {
    title: 'Trace Stream',
    description:
      'See the most recent Traces from the chain, decoded & readable',
  },
  TRANSACTION_STREAM: {
    title: 'Transaction Stream',
    description:
      'See the most recent Transactions from the chain, decoded & readable',
  },
  BLOCK_STREAM: {
    title: 'Block Stream',
    description:
      'See the most recent Blocks from the chain, decoded & readable',
  },

  // *************************************************
  // Data Tracking Services
  // *************************************************
  INVESTIGATIONS: {
    title: 'Your Investigations',
    description: 'Manage all of your investigations here',
  },
  INVESTIGATION_CREATE: {
    title: 'Create Investigation',
    description: 'Create an Investigation',
  },
  INVESTIGATION_DETAILS: {
    title: 'Investigation Details',
    description: 'Details of an Investigation and related saved queries',
  },
  TRACER: {
    title: 'Tracer',
    description:
      'Blockscope Tracer tool to track fund flow activity across chain',
  },
  TRACER_LIST: {
    title: 'Your Tracers',
    description: 'Manage all of your Tracers here',
  },
  MEMOS: { title: 'Your Memos', description: 'Manage all of your memos here' },

  COHORT_ANALYSIS: {
    title: 'Cohort Analysis',
    description: 'Analyze the behavior of a group of addresses.',
  },

  // *************************************************
  // DEV Tools
  // *************************************************
  EVENT_DIRECTORY: {
    title: 'EVM Event Directory',
    description: 'All known EVM Event Signatures and Names',
  },
  FUNCTION_DIRECTORY: {
    title: 'EVM Function Directory',
    description: 'All known EVM Function Signatures and Names',
  },

  WEB3_PROJECTS: {
    title: 'Web3 Projects',
    description: 'Discover entities and actors in the Web3 space',
  },
  WEB3_PROJECT_DETAILS: {
    title: 'Web3 Project',
    description: 'Details of a Web3 Project',
  },
  WEB3_CHAIN_DETAILS: {
    title: 'Web3 Chain',
    description: 'Details of a Web3 Chain',
  },
  WEB3_HACKS: {
    title: 'Web3 Hacks',
    description: 'Discover hack events in the Web3 space',
  },
  WEB3_HACK_DETAILS: {
    title: 'Web3 Hack',
    description: 'Details of a Web3 Hack',
  },
  LABELS: { title: 'Labels', description: 'List of all labels' },
  CONTRACTS: { title: 'Contracts', description: 'List of all saved contracts' },
  WALLETS: { title: 'Saved Wallets', description: 'List of all saved wallets' },

  SECURITY_MONITORING_DASHBOARD: {
    title: 'Security Monitoring',
    description: 'Protect your addresses against illicit activity',
  },
  RISK_RULES: {
    title: 'Risk Rules',
    description: 'Rules set up by your team for security alerts',
  },
  MONITORED_ADDRESSES: {
    title: 'Monitored Addresses',
    description: 'These are the addresses being monitored for security alerts',
  },

  ALERT_INSTANCES: {
    title: 'Alerts',
    description: 'List of all active alerts',
  },
  GUIDE: { title: 'Guide', description: 'A helpful guide to using Blockscope' },

  BLOG: {
    title: 'Blog',
    description: 'Articles and blogs from the Blockscope team',
  },
  CHAT: {
    title: 'Chat',
    description: 'Chat with AI',
  },
  DEMIXER: {
    title: 'Demixer',
    description:
      'Demixer is a tool to analyze the behavior of a mixer and its users.',
  },
};

