export const workflowTypes = {
  INVESTIGATE_FULL_WALLET_PROFILE: 'INVESTIGATE_FULL_WALLET_PROFILE',
  INVESTIGATE_WALLET_FOR_KYC_DATA: 'INVESTIGATE_WALLET_FOR_KYC_DATA',
  INVESTIGATE_WALLET_FOR_ILLICIT_ACTIVITY:
    'INVESTIGATE_WALLET_FOR_ILLICIT_ACTIVITY',
  ANALYZE_DECODER_TRACE_CALL: 'ANALYZE_DECODER_TRACE_CALL',
  INVESTIGATE_FULL_DECODER: 'INVESTIGATE_FULL_DECODER',
  INVESTIGATE_FULL_CONTRACT_ANALYSIS: 'INVESTIGATE_FULL_CONTRACT_ANALYSIS',
  ANALYZE_CURRENT_PAGE: 'ANALYZE_CURRENT_PAGE',
  CUSTOM: 'CUSTOM',
};
