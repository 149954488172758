import { toast } from 'react-toastify';

import { TOAST_HANDLER } from 'blockscope/static/toastIds';

/**
 * Displays a success toast notification with a given message.
 *
 * @param {string} msg - The message to be displayed in the toast notification.
 * @param {string} [id=TOAST_HANDLER.SUCCESS] - The unique identifier for the toast notification.
 */
export const successToast = (msg, id = TOAST_HANDLER.SUCCESS) => {
  toast.success(msg, { theme: 'colored', toastId: id });
};

/**
 * Displays a warning toast notification with a given message.
 *
 * @param {string} msg - The message to be displayed in the toast notification.
 * @param {string} [id=TOAST_HANDLER.WARNING] - The optional ID for the toast notification. Defaults to TOAST_HANDLER.WARNING.
 */
export const warningToast = (msg, id = TOAST_HANDLER.WARNING) => {
  toast.warning(msg, { theme: 'colored', toastId: id });
};

/**
 * Displays an error toast notification with a given message.
 *
 * @param {string} msg - The message to display in the toast notification.
 * @param {string} [id=TOAST_HANDLER.ERROR] - The optional ID for the toast notification.
 */
export const errorToast = (msg, id = TOAST_HANDLER.ERROR) => {
  toast.error(msg, { theme: 'colored', toastId: id });
};

/**
 * Throws a toast for axios error
 * @param {Object} err - Error object from axios
 * @param {Boolean} showPlaceholder - Show default error message if true
 * @param {String} id - Unique toast id to prevent duplicate toasts
 */
export const axiosErrorToast = (
  err,
  showPlaceholder = true,
  id = TOAST_HANDLER.AXIOS_ERROR
) => {
  if (err) {
    if (typeof err === 'object' && err?.response?.data?.message) {
      errorToast(err?.response?.data?.message, id);
    } else {
      errorToast(err, id);
    }
  } else if (showPlaceholder === true) {
    toast.error('Something went wrong', {
      toastId: TOAST_HANDLER.AXIOS_ERROR_PLACEHOLDER,
    });
  }
};

/**
 * Throws a toast for axios success
 * @param {Object} res - Response Object from BE
 * @param {String} id - Unique toast id to prevent duplicate toasts
 */
export const axiosSuccessToast = (res, id = TOAST_HANDLER.AXIOS_SUCCESS) => {
  successToast(res?.data?.message || 'Success', id);
};

