import React from 'react';
import { cloneElement } from 'react';
import { Button } from 'react-bootstrap';

import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';

import Flex from 'blockscope/components/common/Flex';

const NoDataDisplay = ({
  text = 'No Data',
  className,
  icon = <DocumentMagnifyingGlassIcon />,
  buttonText,
  buttonFunction,
  disabled = false,
}) => {
  return (
    <div className={`${className} h-100`}>
      <Flex
        direction='column'
        justifyContent='center'
        alignItems='center'
        className='py-4 h-100'
      >
        <div
          style={{
            backgroundColor: 'rgba(255,255,255,0.5)',
            textAlign: 'center',
          }}
        >
          {cloneElement(icon, {
            className: 'mb-2',
            style: { color: '#ccc', width: '40px', height: '40px' },
          })}
          <p className='fs--1 mb-0 text-center'>{text}</p>
          {buttonText && buttonFunction && (
            <Button
              variant='falcon-primary'
              disabled={disabled}
              onClick={buttonFunction}
              className='fs--2 px-2 py-1 mt-2 text-dark'
            >
              {buttonText}
            </Button>
          )}
        </div>
      </Flex>
    </div>
  );
};

NoDataDisplay.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  icon: PropTypes.node,
  buttonText: PropTypes.string,
  buttonFunction: PropTypes.func,
  disabled: PropTypes.bool,
};

export default NoDataDisplay;
