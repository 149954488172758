import React, { Fragment } from 'react';
import { FiCheck } from 'react-icons/fi';

const WhatWeDo = () => {
  return (
    <Fragment>
      <section className='pt-md-8 pb-md-8 bg-dark'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 text-center'>
              <h6 className='text-uppercase text-success fw-bold'>
                What we offer
              </h6>

              <h1 className='fw-bold text-white'>
                {/* Web3 data platform for storage, analytics & integrations. */}
                {/* {`Blockscope offers a large suite of on-chain data tools.`}\ */}
                {`Tools to understand web3 data in a useful way.`}
              </h1>

              <p className='fs-lg text-success mb-6'>
                {/* Blockchian tools that empowers users to capture, analyze, and
                understand web3 data in a useful way. */}
                {`Blockscope is an all-in-one Data Platform with incredibly easy-to-use data tools for individuals, developers and institutions.`}
              </p>
            </div>
          </div>

          <div className={`row mx-8 text-center`}>
            <div className='col-12 col-md-6 col-lg-3 mb-4 text-300'>
              <h4 className='fw-bold text-white mb-5'>Monitoring</h4>
              <p>Rule-based notifications</p>
              <p>Alerts to Telegram</p>
              <p>Pre-defined capture rules</p>
              <p>Custom capture rules</p>
            </div>

            <div className='col-12 col-md-6 col-lg-3 mb-4 text-300'>
              <h4 className='fw-bold text-white mb-5'>Observability</h4>
              <p>Contract Usage</p>
              <p>No-code Querying</p>
              <p>Address Labels & Tags</p>
            </div>

            <div className='col-12 col-md-6 col-lg-3 mb-4 text-300'>
              <h4 className='fw-bold text-white mb-5'>Compliance</h4>
              <p>OFAC Sanction List</p>
              <p>Blacklist Events</p>
              <p>Wallet Profiling</p>
              <p>Transaction Decoder</p>
            </div>

            <div className='col-12 col-md-6 col-lg-3 mb-4 text-300'>
              <h4 className='fw-bold text-white mb-5'>Forensics</h4>
              <p>Token Flow</p>
              <p>Cross-chain Tracing</p>
              <p>Address Clustering</p>
              <p>Investigations</p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default WhatWeDo;

