import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Modal, Table } from 'react-bootstrap';

import Explainer from '../Explainer';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import PropTypes from 'prop-types';

import NoDataDisplay from 'blockscope/components/blockscopeCommon/NoDataDisplay';
import PageControlsV2 from 'blockscope/components/blockscopeCommon/table/PageControlsV2';
import { Searchbar } from 'blockscope/components/blockscopeCommon/table/Searchable';
import FalconCardHeader from 'blockscope/components/common/FalconCardHeader';
import Flex from 'blockscope/components/common/Flex';
import IconButton from 'blockscope/components/common/IconButton';

export const TableLogic = ({
  data,
  rowHeight,
  table,
  showTableHeaders,
  noDataText,
  noDataButtonText,
  noDataButtonFunction,
  noDataDisabledState,
  noDataHeaderDisplay = false,
  ignoreNoData = false,
  ...props
}) => {
  return (
    <Fragment>
      <Table className='mb-0' responsive {...props} borderless>
        {showTableHeaders && (
          <thead
            style={{
              borderBottom: '1px solid black',
              display:
                data.length > 0 || noDataHeaderDisplay ? 'contents' : 'none',
            }}
          >
            {table.getHeaderGroups().length > 0 && (
              <tr
                key={table.getHeaderGroups()[0].id}
                className='border-bottom border-dark'
              >
                {table.getHeaderGroups()[0].headers.map((header) => (
                  <th
                    key={header.id}
                    className={`text-${
                      header.column.columnDef.headerAlignment ?? 'start'
                    } px-0 py-0 me-1`}
                    colSpan={header.colSpan}
                  >
                    <h6 className='ps-0 py-0'>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </h6>
                  </th>
                ))}
              </tr>
            )}
          </thead>
        )}

        {data.length > 0 ? (
          <tbody>
            {table.getRowModel().rows.map((row, index) => (
              <tr
                key={row.id}
                style={{ height: rowHeight }}
                className={
                  index !== table.getRowModel().rows.length - 1
                    ? 'border-bottom'
                    : ''
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <Fragment key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        ) : null}
      </Table>
      {(data.length === 0 || table.getRowModel().rows.length === 0) &&
        !ignoreNoData && (
          <div style={{ height: '100%' }}>
            <NoDataDisplay
              className='mt-1'
              text={noDataText}
              buttonText={noDataButtonText}
              buttonFunction={noDataButtonFunction}
              disabled={noDataDisabledState}
            />
          </div>
        )}
    </Fragment>
  );
};
TableLogic.propTypes = {
  data: PropTypes.array,
  rowHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  table: PropTypes.object,
  showTableHeaders: PropTypes.bool,
  noDataText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  noDataButtonText: PropTypes.string,
  noDataButtonFunction: PropTypes.func,
  noDataDisabledState: PropTypes.bool,
  noDataHeaderDisplay: PropTypes.bool,
};

export const BlockscopeTableV2 = ({
  className,
  height,
  rowHeight = 'auto',
  maxHeight,

  title,

  data,
  columns,

  noDataText,
  noDataButtonText,
  noDataButtonFunction,
  noDataDisabledState,
  noDataHeaderDisplay = false,
  ignoreNoData = false,

  rowsPerPage = 10,
  paginate,
  searchable,
  searchFromOutside,
  showTableHeaders,
  ...props
}) => {
  const [globalFilter, setGlobalFilter] = useState('');

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useEffect(() => {
    if (paginate) {
      table.setPageSize(rowsPerPage);
    } else {
      table.setPageSize(data.length);
    }
  }, [data, paginate]);

  useEffect(() => {
    if (searchFromOutside !== undefined) {
      setGlobalFilter(searchFromOutside);
    }
  }, [searchFromOutside]);

  const calculateStableTableHeight = () => {
    if (height) {
      return height;
    }

    if (
      table.getState().pagination.pageIndex > 0 &&
      data.length > 0 &&
      maxHeight
    ) {
      return maxHeight;
    }

    return 'auto';
  };

  return (
    <div
      {...props}
      style={{
        ...props.style,
        height: calculateStableTableHeight(),
        maxHeight: maxHeight,
      }}
      className={className}
    >
      {searchFromOutside === undefined && searchable && (
        <Flex justifyContent='end'>
          <div style={{ width: '50%' }} className='mb-3'>
            <Searchbar
              setSearchText={setGlobalFilter}
              searchText={globalFilter}
            />
          </div>
        </Flex>
      )}
      <span>{title}</span>
      <div style={{ overflowY: 'auto', height: '100%' }}>
        <TableLogic
          table={table}
          data={data}
          noDataButtonFunction={noDataButtonFunction}
          noDataButtonText={noDataButtonText}
          noDataDisabledState={noDataDisabledState}
          noDataText={noDataText}
          noDataHeaderDisplay={noDataHeaderDisplay}
          showTableHeaders={showTableHeaders}
          rowHeight={rowHeight}
          ignoreNoData={ignoreNoData}
        />
      </div>

      {paginate && data.length > rowsPerPage && (
        <Card.Footer>
          <PageControlsV2
            page={table.getState().pagination.pageIndex + 1}
            nextPage={table.nextPage}
            prevPage={table.previousPage}
            setPage={table.setPageIndex}
            canNextPage={table.getCanNextPage()}
            canPrevPage={table.getCanPreviousPage()}
            rowCount={table.getFilteredRowModel().rows.length}
            showCount
            rowsPerPage={rowsPerPage}
          />
        </Card.Footer>
      )}
    </div>
  );
};

BlockscopeTableV2.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  titleTag: PropTypes.string,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowsPerPage: PropTypes.number,
  noDataText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  noDataButtonText: PropTypes.string,
  noDataButtonFunction: PropTypes.func,
  noDataDisabledState: PropTypes.bool,
  showTableHeaders: PropTypes.bool,
  paginate: PropTypes.bool,
  searchable: PropTypes.bool,
  noDataHeaderDisplay: PropTypes.bool,
};

const BlockscopeTableCardV2 = ({
  className,
  cardClassName,
  height,
  rowHeight = 'auto',
  minHeight,
  maxHeight,

  title,
  titleTag,
  titleExplainerText,
  titleElements,

  headerStartEl,
  headerEndEl,

  data,
  columns,

  noDataText,
  noDataButtonText,
  noDataButtonFunction,
  noDataDisabledState,
  noDataHeaderDisplay = false,

  rowsPerPage = 10,
  paginate,
  searchable,
  searchFromOutside,
  showTableHeaders,
  autoResetPageIndex = true,
  pageInput = true,

  downloadResultsFunc,
  ...props
}) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    autoResetPageIndex,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });
  useEffect(() => {
    if (paginate) {
      table.setPageSize(rowsPerPage);
    } else {
      table.setPageSize(data.length);
    }
  }, [data, paginate]);

  useEffect(() => {
    if (searchFromOutside !== undefined) {
      setGlobalFilter(searchFromOutside);
    }
  }, [searchFromOutside]);

  const calculateStableTableHeight = () => {
    // default override
    if (height) {
      return height;
    }

    // if page is 2nd or greater and has data use max height
    if (
      table.getState().pagination.pageIndex > 0 &&
      data.length > 0 &&
      maxHeight
    ) {
      return maxHeight;
    }

    // if page is 1st and has no data use min height or auto if min height is not set
    if (table.getState().pagination.pageIndex === 0 && data.length === 0) {
      return minHeight || 'auto';
    }

    // if page is 1st and has data and has row height and min height
    if (table.getState().pagination.pageIndex === 0 && rowHeight && minHeight) {
      // if row height is set calculate the total height of all rows and add 88px for header and footer
      const rowTotalHeight =
        Number(rowHeight.slice(0, -2)) * (data.length + 1) + 88;

      return minHeight > rowTotalHeight ? minHeight : rowTotalHeight;
    }

    return 'auto';
  };

  return (
    <div className={className}>
      <Card
        {...props}
        className={cardClassName}
        style={{
          ...props.style,
          height: calculateStableTableHeight(),
          minHeight: minHeight,
          maxHeight: maxHeight,
        }}
      >
        <FalconCardHeader
          title={title}
          titleTag={titleTag}
          titleExplainerText={titleExplainerText}
          light
          startEl={headerStartEl}
          endEl={
            headerEndEl || (
              <Flex>
                {downloadResultsFunc && (
                  <Explainer text='Download'>
                    <IconButton
                      iconSize={'xs'}
                      size='sm'
                      className={'me-2 mt-1'}
                      variant='falcon-default'
                      icon={'download'}
                      onClick={() => {
                        downloadResultsFunc(
                          table
                            .getFilteredRowModel()
                            .rows.map((row) => row.original)
                        );
                      }}
                    />
                  </Explainer>
                )}
                {searchFromOutside === undefined && searchable ? (
                  <Searchbar
                    setSearchText={setGlobalFilter}
                    searchText={globalFilter}
                  />
                ) : null}
              </Flex>
            )
          }
        >
          {titleElements}
        </FalconCardHeader>
        <Card.Body style={{ overflowY: 'auto' }}>
          <div style={{ height: '100%' }}>
            <TableLogic
              table={table}
              data={data}
              noDataButtonFunction={noDataButtonFunction}
              noDataButtonText={noDataButtonText}
              noDataDisabledState={noDataDisabledState}
              noDataText={noDataText}
              noDataHeaderDisplay={noDataHeaderDisplay}
              showTableHeaders={showTableHeaders}
              rowHeight={rowHeight}
            />
          </div>
        </Card.Body>

        {paginate && data.length > rowsPerPage && (
          <Card.Footer>
            <PageControlsV2
              page={table.getState().pagination.pageIndex + 1}
              nextPage={table.nextPage}
              prevPage={table.previousPage}
              setPage={table.setPageIndex}
              canNextPage={table.getCanNextPage()}
              canPrevPage={table.getCanPreviousPage()}
              rowCount={table.getFilteredRowModel().rows.length}
              showCount
              rowsPerPage={rowsPerPage}
              pageInput={pageInput}
            />
          </Card.Footer>
        )}
      </Card>
    </div>
  );
};

export const BlockscopeTableModalV2 = ({
  className,
  rowHeight = 'auto',

  buttonText,
  buttonProps,
  title,
  data,
  columns,

  noDataText,
  noDataButtonText,
  noDataButtonFunction,
  noDataDisabledState,

  rowsPerPage = 10,
  paginate,
  searchable,
  showTableHeaders,
  ...props
}) => {
  const [showModal, setShowModal] = useState(false);

  const toggle = () => {
    setShowModal(!showModal);
  };
  const [globalFilter, setGlobalFilter] = useState('');
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });
  useEffect(() => {
    if (paginate) {
      table.setPageSize(rowsPerPage);
    } else {
      table.setPageSize(data.length);
    }
  }, [data, paginate]);
  return (
    <div className={className}>
      <Button
        variant='falcon-default'
        size='sm'
        {...buttonProps}
        onClick={() => toggle()}
      >
        {buttonText}
      </Button>
      <Modal show={showModal} onHide={() => toggle()} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        {searchable && (
          <Searchbar
            setSearchText={setGlobalFilter}
            searchText={globalFilter}
          />
        )}

        <Modal.Body style={{ overflowY: 'auto' }}>
          <TableLogic
            table={table}
            data={data}
            noDataButtonFunction={noDataButtonFunction}
            noDataButtonText={noDataButtonText}
            noDataDisabledState={noDataDisabledState}
            noDataText={noDataText}
            showTableHeaders={showTableHeaders}
            rowHeight={rowHeight}
          />
        </Modal.Body>

        {paginate && data.length > rowsPerPage && (
          <Modal.Footer>
            <PageControlsV2
              page={table.getState().pagination.pageIndex + 1}
              nextPage={table.nextPage}
              prevPage={table.previousPage}
              setPage={table.setPageIndex}
              canNextPage={table.getCanNextPage()}
              canPrevPage={table.getCanPreviousPage()}
              rowCount={data.length}
              showCount
              rowsPerPage={rowsPerPage}
            />
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
};

BlockscopeTableCardV2.propTypes = {
  ...BlockscopeTableV2.propTypes,
  titleExplainerText: PropTypes.string,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowsPerPage: PropTypes.number,
  noDataText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  noDataButtonText: PropTypes.string,
  noDataButtonFunction: PropTypes.func,
  noDataDisabledState: PropTypes.bool,
  noDataHeaderDisplay: PropTypes.bool,
  headerEndEl: PropTypes.object,
  headerStartEl: PropTypes.node,
};
BlockscopeTableModalV2.propTypes = {
  ...BlockscopeTableV2.propTypes,
};

export default BlockscopeTableCardV2;

