import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import loadable from '@loadable/component';
import AppContext from 'context/Context';
import is from 'is_js';

import { ORG_SERVICES } from 'blockscope/static/blockscopeServices';
import { PAGES } from 'blockscope/static/pageInfo';

import EnabledServiceRoute from 'blockscope/routes/EnabledServiceRoute';
import FetchOrgRoute_Bloc from 'blockscope/routes/FetchOrgRoute';
import OnboardedRoute from 'blockscope/routes/OnboardedRoute';
import ProtectedRoute_Bloc from 'blockscope/routes/ProtectedRoute';

import AuthSimpleLayout_Bloc from 'blockscope/layouts/AuthSimpleLayout';
import ErrorLayout_Bloc from 'blockscope/layouts/ErrorLayout';
import MainLayout_Bloc from 'blockscope/layouts/MainLayout';
import ProductsLayout_Bloc from 'blockscope/layouts/ProductsLayout';
import SolutionsLayout_Bloc from 'blockscope/layouts/SolutionsLayout';

import PageWithHelmet from 'blockscope/components/blockscopeCommon/PageWithHelmet';
import { CloseButton } from 'blockscope/components/common/Toast';

import ChatPage from 'blockscope/components/aiSystem/pages/ChatPage';
import MyChatsPage from 'blockscope/components/aiSystem/pages/MyChatsPage';
import ConfirmMail_Bloc from 'blockscope/components/authentication/simple/ConfirmMail';
import ForgetPassword_Bloc from 'blockscope/components/authentication/simple/ForgetPassword';
import PasswordReset_Bloc from 'blockscope/components/authentication/simple/PasswordReset';
import SimpleLoginPage_Bloc from 'blockscope/components/authentication/simple/SimpleLoginPage.js';
import SimpleRegistrationPage_Bloc from 'blockscope/components/authentication/simple/SimpleRegistrationPage.js';
import Calendly from 'blockscope/components/calendly/Calendly';
import Error404 from 'blockscope/components/errors/Error404';
import Error500 from 'blockscope/components/errors/Error500';
import LandingPage_Bloc from 'blockscope/components/pages/landing/page/LandingPage.js';
import TallyRegisterationPage from 'blockscope/components/pages/landing/page/TallyRegisteration';

// MISC AUTHENTICATED PAGES
const ChatPanel = loadable(() =>
  import(
    /*webpackChunkName:'chat-panel' */ 'blockscope/components/aiSystem/panel/ChatPanel'
  )
);

const AddressPanel = loadable(() =>
  import(
    /*webpackChunkName:'address-panel' */ 'blockscope/components/addressPanel/AddressPanel'
  )
);

const AllServicesPage = loadable(() =>
  import(
    /*webpackChunkName:'all-services-page' */ 'blockscope/components/dashboard/AllServicesPage'
  )
);

const OrgDashboardPage_Bloc = loadable(() =>
  import(
    /*webpackChunkName:'org-dashboard-page' */ 'blockscope/components/dashboard/OrgDashboardPage'
  )
);

const SelectOrgPage_Bloc = loadable(() =>
  import(
    /*webpackChunkName:'select-org-page' */ 'blockscope/components/org/SelectOrgPage'
  )
);

const AcceptInvitePage_Bloc = loadable(() =>
  import(
    /*webpackChunkName:'accept-invite-page' */ 'blockscope/components/invite/AcceptInvitePage'
  )
);

const ContractDetailsPage = loadable(() =>
  import(
    /*webpackChunkName:'contract-details-page' */ 'blockscope/components/capture/pages/ContractDetailsPage'
  )
);

const MyNotifications = loadable(() =>
  import(
    /*webpackChunkName:'my-notifications' */ 'blockscope/components/notification/pages/MyNotifications'
  )
);

// COMPANY INFO PAGES
const AllToolkitsPage = loadable(() =>
  import(
    /*webpackChunkName:'all-toolkits-page' */ 'blockscope/components/pages/landing/page/AllToolkitsPage'
  )
);

const CompanyPage = loadable(() =>
  import(
    /*webpackChunkName:'company-page' */ 'blockscope/components/pages/landing/page/CompanyPage'
  )
);

const GuidePage = loadable(() =>
  import(
    /*webpackChunkName:'guide-page' */ 'blockscope/components/guide/pages/GuidePage'
  )
);

const ContactUsPage = loadable(() =>
  import(
    /*webpackChunkName:'contact-us-page' */ 'blockscope/components/pages/ContactUsPage'
  )
);

const MyMemosPage = loadable(() =>
  import(
    /*webpackChunkName:'my-memos-page' */ 'blockscope/components/memos/pages/MyMemosPage'
  )
);

// ADMIN
const AdminControlPage = loadable(() =>
  import(
    /*webpackChunkName:'admin-control-page' */ 'blockscope/components/admin/AdminControlPage'
  )
);

// SECMON
const SecurityMainPage = loadable(() =>
  import(
    /*webpackChunkName:'security-main-page' */ 'blockscope/components/capture/securityMonitoring/pages/SecurityMainPage'
  )
);

const AlertInstancePage = loadable(() =>
  import(
    /*webpackChunkName:'alert-instance-page' */ 'blockscope/components/capture/securityMonitoring/pages/AlertInstancePage'
  )
);

// CONTRACT BREAKDOWN
const ContractBreakdownResultsPage = loadable(() =>
  import(
    /*webpackChunkName:'contract-breakdown-results-page' */ 'blockscope/components/contractBreakdown/pages/ResultsPage'
  )
);

const ContractBreakdownSearchPage = loadable(() =>
  import(
    /*webpackChunkName:'contract-breakdown-search-page' */ 'blockscope/components/contractBreakdown/pages/SearchPage'
  )
);

// ENTITY INTERACTION
const EntityInteractionResultsPage = loadable(() =>
  import(
    /*webpackChunkName:'entity-interaction-results-page' */ 'blockscope/components/entityInteraction/pages/ResultsPage'
  )
);

const EntityInteractionSearchPage = loadable(() =>
  import(
    /*webpackChunkName:'entity-interaction-search-page' */ 'blockscope/components/entityInteraction/pages/SearchPage'
  )
);

// ADVANCED SEARCH
const AdvancedSearchPage = loadable(() =>
  import(
    /*webpackChunkName:'advanced-search-page' */ 'blockscope/components/advancedSearch/pages/SearchPage'
  )
);
const AdvancedSearchResultsPage = loadable(() =>
  import(
    /*webpackChunkName:'advanced-search-results-page' */ 'blockscope/components/advancedSearch/pages/ResultsPage'
  )
);

const DemixerPage = loadable(() =>
  import(
    /*webpackChunkName:'demixer-page' */ 'blockscope/components/demixer/pages/DemixerPage'
  )
);

// CONTRACT USAGE
const ContractUsageResultsPage = loadable(() =>
  import(
    /*webpackChunkName:'contract-usage-results-page' */ 'blockscope/components/contractUsage/ContractUsageResultsPage'
  )
);

const ContractUsageSearchPage = loadable(() =>
  import(
    /*webpackChunkName:'contract-usage-search-page' */ 'blockscope/components/contractUsage/ContractUsageSearchPage'
  )
);

// INVESTIGATIONS
// const CreateInvestigation = loadable(() =>
//   import(
//     /*webpackChunkName:'create-investigation' */ 'blockscope/components/Investigator/pages/CreateInvestigation'
//   )
// );

// const InvestigationDetailsPage = loadable(() =>
//   import(
//     /*webpackChunkName:'investigation-details-page' */ 'blockscope/components/Investigator/pages/InvestigationDetailsPage'
//   )
// );

// const MyInvestigations = loadable(() =>
//   import(
//     /*webpackChunkName:'my-investigations' */ 'blockscope/components/Investigator/pages/MyInvestigations'
//   )
// );

// DIRECTORY SERVICES
const AllLabelsPage = loadable(() =>
  import(
    /*webpackChunkName:'all-labels-page' */ 'blockscope/components/labels/pages/AllLabelsPage'
  )
);

const EventsDirectoryPage_Bloc = loadable(() =>
  import(
    /*webpackChunkName:'events-directory-page' */ 'blockscope/components/directoryEvents/EventsDirectoryPage'
  )
);

const FunctionsDirectoryPage_Bloc = loadable(() =>
  import(
    /*webpackChunkName:'functions-directory-page' */ 'blockscope/components/directoryFunctions/FunctionsDirectoryPage'
  )
);

const ChainDetailsPage = loadable(() =>
  import(
    /*webpackChunkName:'chain-details-page' */ 'blockscope/components/projectDirectory/pages/ChainDetailsPage'
  )
);

// const ProjectDetailsPage = loadable(() =>
//   import(
//     /*webpackChunkName:'project-details-page' */ 'blockscope/components/projectDirectory/pages/ProjectDetailsPage'
//   )
// );

// const ProjectDirectoryPage = loadable(() =>
//   import(
//     /*webpackChunkName:'project-directory-page' */ 'blockscope/components/projectDirectory/pages/ProjectDirectoryPage'
//   )
// );

// const HackDetailsPage = loadable(() =>
//   import(
//     /*webpackChunkName:'hack-details-page' */ 'blockscope/components/hacksDirectory/pages/HackDetailsPage'
//   )
// );

// const HackDirectoryPage = loadable(() =>
//   import(
//     /*webpackChunkName:'hack-directory-page' */ 'blockscope/components/hacksDirectory/pages/HackDirectoryPage'
//   )
// );

// POLICIES/TOS
const ToSPage = loadable(() =>
  import(
    /*webpackChunkName:'tos-page' */ 'blockscope/components/pages/landing/page/TermsAndConditions'
  )
);

const PrivacyPolicyPage = loadable(() =>
  import(
    /*webpackChunkName:'privacy-policy-page' */ 'blockscope/components/pages/landing/page/PrivacyPolicy'
  )
);

// SETTINGS
const OrgSettingsPage_Bloc = loadable(() =>
  import(
    /*webpackChunkName:'org-settings-page' */ 'blockscope/components/settings/OrgSettingsPage'
  )
);

const UserSettingsPage_Bloc = loadable(() =>
  import(
    /*webpackChunkName:'user-settings-page' */ 'blockscope/components/settings/UserSettingsPage'
  )
);

// BLOCK BREAKDOWN
const BlockBreakdownPage = loadable(() =>
  import(
    /*webpackChunkName:'block-breakdown-page' */ 'blockscope/components/blockBreakdown/pages/BlockBreakdownPage'
  )
);

const BlockSearchPage = loadable(() =>
  import(
    /*webpackChunkName:'block-search-page' */ 'blockscope/components/blockBreakdown/pages/BlockSearchPage'
  )
);

// WATCHTOWER
const CreateWatchtowerPage_Bloc = loadable(() =>
  import(
    /*webpackChunkName:'create-watchtower-page' */ 'blockscope/components/capture/pages/CreateWatchtowerPage'
  )
);

const PublicWatchtowerDetailsPage = loadable(() =>
  import(
    /*webpackChunkName:'public-watchtower-details-page' */ 'blockscope/components/capture/pages/PublicWatchtowerDetailsPage'
  )
);

const PublicWatchtowersPage = loadable(() =>
  import(
    /*webpackChunkName:'public-watchtower-page' */ 'blockscope/components/capture/pages/PublicWatchtowerPage'
  )
);

const WatchtowerDetailsPage_Bloc = loadable(() =>
  import(
    /*webpackChunkName:'watchtower-details-page' */ 'blockscope/components/capture/pages/WatchtowerDetailsPage'
  )
);

const WatchtowerMainPage = loadable(() =>
  import(
    /*webpackChunkName:'watchtower-main-page' */ 'blockscope/components/capture/pages/WatchtowerMainPage'
  )
);

// TRACER
const MyTracersPage = loadable(() =>
  import(
    /*webpackChunkName:'my-tracers-page' */ 'blockscope/components/tracer/MyTracersPage'
  )
);

const TracerPage = loadable(() =>
  import(
    /*webpackChunkName:'tracer-page' */ 'blockscope/components/tracer/TracerPage'
  )
);

// PIPELINES
const PipelineDetailsPage_Bloc = loadable(() =>
  import(
    /*webpackChunkName:'pipeline-details-page' */ 'blockscope/components/capture/pages/PipelineDetailsPage'
  )
);

const PipelineMainPage = loadable(() =>
  import(
    /*webpackChunkName:'pipeline-main-page' */ 'blockscope/components/capture/pages/PipelineMainPage'
  )
);

const CreatePipelinePage_Bloc = loadable(() =>
  import(
    /*webpackChunkName:'create-pipeline-page' */ 'blockscope/components/capture/pages/CreatePipelinePage'
  )
);

const DeliverySettingsSummary = loadable(() =>
  import(
    /*webpackChunkName:'delivery-settings-summary' */ 'blockscope/components/capture/components/deliverySettings/components/DeliverySettingsSummary'
  )
);

// BLOGS
const BlogPage = loadable(() =>
  import(
    /*webpackChunkName:'blog-page' */ 'blockscope/components/pages/blog/BlogPage'
  )
);

const AllBlogsPage = loadable(() =>
  import(
    /*webpackChunkName:'all-blogs-page' */ 'blockscope/components/pages/blog/AllBlogsPage'
  )
);

// TRANSACTION DECODER PAGES
const TransactionDecoderResultsPage = loadable(() =>
  import(
    /*webpackChunkName:'transaction-decoder-results-page' */ 'blockscope/components/transactionDecoder/pages/TransactionDecoderResultsPage'
  )
);

const TransactionDecoderSearchPage = loadable(() =>
  import(
    /*webpackChunkName:'transaction-decoder-search-page' */ 'blockscope/components/transactionDecoder/pages/TransactionDecoderSearchPage'
  )
);

// TRANSACTION SIMULATOR PAGES
const TransactionSimulatorSearchPage = loadable(() =>
  import(
    /*webpackChunkName:'transaction-simulator-search-page' */ 'blockscope/components/transactionSimulator/pages/SearchPage'
  )
);

const TransactionSimulatorResultsPage = loadable(() =>
  import(
    /*webpackChunkName:'transaction-simulator-results-page' */ 'blockscope/components/transactionSimulator/pages/ResultsPage'
  )
);

// COHORT ANALYSI PAGES
const CohortAnalysisSearchPage = loadable(() =>
  import(
    /*webpackChunkName:'cohort-analysis-search-page' */ 'blockscope/components/CohortAnalysis/pages/SearchPage'
  )
);

const CohortAnalysisResultsPage = loadable(() =>
  import(
    /*webpackChunkName:'cohort-analysis-results-page' */ 'blockscope/components/CohortAnalysis/pages/ResultsPage'
  )
);

// WALLET PROFILER PAGES
const WalletProfilePage = loadable(() =>
  import(
    /*webpackChunkName:'wallet-profile-page' */ 'blockscope/components/walletProfile/WalletProfilePage'
  )
);

const WalletSearchPage = loadable(() =>
  import(
    /*webpackChunkName:'wallet-search-page' */ 'blockscope/components/walletProfile/WalletSearchPage'
  )
);

// PRCING PAGES
const PricingPage = loadable(() =>
  import(
    /*webpackChunkName:'pricing-page' */ 'blockscope/components/pages/pricing/PricingPage'
  )
);

// SOLUTION PAGES
const DefenderPage = loadable(() =>
  import(
    /*webpackChunkName:'solution-landing-defender' */ 'blockscope/components/pages/solutions/defender/page/DefenderPage'
  )
);
const DevKitPage = loadable(() =>
  import(
    /*webpackChunkName:'solution-landing-devkit' */ 'blockscope/components/pages/solutions/devkit/page/DevKitPage'
  )
);
const MarketerPage = loadable(() =>
  import(
    /*webpackChunkName:'solution-landing-marketer' */ 'blockscope/components/pages/solutions/marketer/page/MarketerPage'
  )
);
const StudioPage = loadable(() =>
  import(
    /*webpackChunkName:'solution-landing-studio' */ 'blockscope/components/pages/solutions/studio/page/StudioPage'
  )
);

// PRODUCT PAGES
const WatchtowerPage_Landing = loadable(() =>
  import(
    /*webpackChunkName:'product-landing-watchtower' */ 'blockscope/components/pages/products/watchtower/page/WatchtowerPage'
  )
);
const ContractUsagePage_Landing = loadable(() =>
  import(
    /*webpackChunkName:'product-landing-contract-usage' */ 'blockscope/components/pages/products/contractUsage/page/ContractUsagePage'
  )
);
const BlockBreakdownPage_Landing = loadable(() =>
  import(
    /*webpackChunkName:'product-landing-block-breakdown' */ 'blockscope/components/pages/products/blockBreakdown/page/BlockBreakdownPage'
  )
);
const TracerPage_Landing = loadable(() =>
  import(
    /*webpackChunkName:'product-landing-tracer' */ 'blockscope/components/pages/products/tracer/page/TracerPage'
  )
);
const TransactionDecoderPage_Landing = loadable(() =>
  import(
    /*webpackChunkName:'product-landing-transaction-decoder' */ 'blockscope/components/pages/products/transactionDecoder/page/TransactionDecoderPage'
  )
);
const WalletProfilerPage_Landing = loadable(() =>
  import(
    /*webpackChunkName:'product-landing-wallet-profile' */ 'blockscope/components/pages/products/walletProfiler/page/WalletProfilerPage'
  )
);
const PipelinesPage_Landing = loadable(() =>
  import(
    /*webpackChunkName:'product-landing-pipelines' */ 'blockscope/components/pages/products/pipelines/page/PipelinesPage'
  )
);
const SecurityMonitoringPage_Landing = loadable(() =>
  import(
    /*webpackChunkName:'product-landing-security-monitoring' */ 'blockscope/components/pages/products/securityMonitoring/page/SecurityMonitoringPage'
  )
);
const WebDirectoryPage_Landing = loadable(() =>
  import(
    /*webpackChunkName:'product-landing-web-directory' */ 'blockscope/components/pages/products/webDirectory/page/WebDirectoryPage'
  )
);
const InvestigatorPage_Landing = loadable(() =>
  import(
    /*webpackChunkName:'product-landing-investigator' */ 'blockscope/components/pages/products/investigator/page/InvestigatorPage'
  )
);
const ApiServicesPage_Landing = loadable(() =>
  import(
    /*webpackChunkName:'product-landing-investigator' */ 'blockscope/components/pages/products/apiServices/page/ApiServicesPage'
  )
);

const Layout = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
  }, [HTMLClassList]);

  return (
    <>
      <Routes>
        {/* BLOCKSCOPE START */}
        <Route
          path='/'
          element={
            <PageWithHelmet>
              <LandingPage_Bloc />
            </PageWithHelmet>
          }
        />

        <Route
          path='/about-toolkits'
          element={
            <PageWithHelmet>
              <AllToolkitsPage />
            </PageWithHelmet>
          }
        />
        <Route
          path='/signup'
          element={
            <PageWithHelmet>
              <TallyRegisterationPage />
            </PageWithHelmet>
          }
        />

        <Route
          path='/pricing'
          element={
            <PageWithHelmet>
              <PricingPage />
            </PageWithHelmet>
          }
        />

        <Route
          path='/company'
          element={
            <PageWithHelmet>
              <CompanyPage />
            </PageWithHelmet>
          }
        />

        <Route
          path='/blog'
          element={
            <PageWithHelmet
              title={PAGES.BLOG.title}
              description={PAGES.BLOG.description}
            >
              <AllBlogsPage />
            </PageWithHelmet>
          }
        />
        <Route path='/blog/:title' element={<BlogPage />} />

        <Route
          path='/privacy-policy'
          element={
            <PageWithHelmet>
              <PrivacyPolicyPage />
            </PageWithHelmet>
          }
        />

        <Route
          path='/tos'
          element={
            <PageWithHelmet>
              <ToSPage />
            </PageWithHelmet>
          }
        />

        {/*- ------------- Solutions ---------------------------  */}
        <Route
          element={
            <PageWithHelmet
              title={PAGES.LANDING_SOLUTIONS.title}
              description={PAGES.LANDING_SOLUTIONS.description}
            >
              <SolutionsLayout_Bloc />
            </PageWithHelmet>
          }
        >
          <Route
            path='/solutions/devkit'
            element={
              <PageWithHelmet
                title={PAGES.LANDING_DEVKIT.title}
                description={PAGES.LANDING_DEVKIT.description}
              >
                <DevKitPage />
              </PageWithHelmet>
            }
          />
          <Route
            path='/solutions/marketer'
            element={
              <PageWithHelmet
                title={PAGES.LANDING_MARKETER.title}
                description={PAGES.LANDING_MARKETER.description}
              >
                <MarketerPage />
              </PageWithHelmet>
            }
          />
          <Route
            path='/solutions/studio'
            element={
              <PageWithHelmet
                title={PAGES.LANDING_STUDIO.title}
                description={PAGES.LANDING_STUDIO.description}
              >
                <StudioPage />
              </PageWithHelmet>
            }
          />
          <Route
            path='/solutions/defender'
            element={
              <PageWithHelmet
                title={PAGES.LANDING_DEFENDER.title}
                description={PAGES.LANDING_DEFENDER.description}
              >
                <DefenderPage />
              </PageWithHelmet>
            }
          />
        </Route>

        {/*- ------------- Products ---------------------------  */}
        <Route
          element={
            <PageWithHelmet
              title={PAGES.LANDING_PRODUCTS.title}
              description={PAGES.LANDING_PRODUCTS.description}
            >
              <ProductsLayout_Bloc />
            </PageWithHelmet>
          }
        >
          <Route
            path='/products/watchtower'
            element={
              <PageWithHelmet
                title={PAGES.LANDING_WATCHTOWER.title}
                description={PAGES.LANDING_WATCHTOWER.description}
              >
                <WatchtowerPage_Landing />
              </PageWithHelmet>
            }
          />
          <Route
            path='/products/contract-usage'
            element={
              <PageWithHelmet
                title={PAGES.LANDING_CONTRACT_USAGE.title}
                description={PAGES.LANDING_CONTRACT_USAGE.description}
              >
                <ContractUsagePage_Landing />
              </PageWithHelmet>
            }
          />
          <Route
            path='/products/block-breakdown'
            element={
              <PageWithHelmet
                title={PAGES.LANDING_BLOCK_BREAKDOWN.title}
                description={PAGES.LANDING_BLOCK_BREAKDOWN.description}
              >
                <BlockBreakdownPage_Landing />
              </PageWithHelmet>
            }
          />
          <Route
            path='/products/tracer'
            element={
              <PageWithHelmet
                title={PAGES.LANDING_TRACER.title}
                description={PAGES.LANDING_TRACER.description}
              >
                <TracerPage_Landing />
              </PageWithHelmet>
            }
          />
          <Route
            path='/products/transaction-decoder'
            element={
              <PageWithHelmet
                title={PAGES.LANDING_TRANSACTION_DECODER.title}
                description={PAGES.LANDING_TRANSACTION_DECODER.description}
              >
                <TransactionDecoderPage_Landing />
              </PageWithHelmet>
            }
          />
          <Route
            path='/products/wallet-profiler'
            element={
              <PageWithHelmet
                title={PAGES.LANDING_WALLET_PROFILER.title}
                description={PAGES.LANDING_WALLET_PROFILER.description}
              >
                <WalletProfilerPage_Landing />
              </PageWithHelmet>
            }
          />
          <Route
            path='/products/pipelines'
            element={
              <PageWithHelmet
                title={PAGES.LANDING_PIPELINES.title}
                description={PAGES.LANDING_PIPELINES.description}
              >
                <PipelinesPage_Landing />
              </PageWithHelmet>
            }
          />
          <Route
            path='/products/contract-guard'
            element={
              <PageWithHelmet
                title={PAGES.LANDING_SECURITY_MONITORING.title}
                description={PAGES.LANDING_SECURITY_MONITORING.description}
              >
                <SecurityMonitoringPage_Landing />
              </PageWithHelmet>
            }
          />
          <Route
            path='/products/web-directory'
            element={
              <PageWithHelmet
                title={PAGES.LANDING_WEB_DIRECTORY.title}
                description={PAGES.LANDING_WEB_DIRECTORY.description}
              >
                <WebDirectoryPage_Landing />
              </PageWithHelmet>
            }
          />
          <Route
            path='/products/investigator'
            element={
              <PageWithHelmet
                title={PAGES.LANDING_INVESTIGATOR.title}
                description={PAGES.LANDING_INVESTIGATOR.description}
              >
                <InvestigatorPage_Landing />
              </PageWithHelmet>
            }
          />
          <Route
            path='/products/api-services'
            element={
              <PageWithHelmet
                title={PAGES.LANDING_APIS.title}
                description={PAGES.LANDING_APIS.description}
              >
                <ApiServicesPage_Landing />
              </PageWithHelmet>
            }
          />
        </Route>

        {/*- ------------- Errors ---------------------------  */}
        <Route
          element={
            <PageWithHelmet
              title={PAGES.ERROR.title}
              description={PAGES.ERROR.description}
            >
              <ErrorLayout_Bloc />
            </PageWithHelmet>
          }
        >
          <Route path='errors/404' element={<Error404 />} />
          <Route path='errors/500' element={<Error500 />} />
        </Route>

        <Route
          path='/calendly'
          element={
            <PageWithHelmet
              title={PAGES.CALENDLY.title}
              description={PAGES.CALENDLY.description}
            >
              <Calendly />
            </PageWithHelmet>
          }
        />

        {/*- ------------- Authentication ---------------------------  */}
        <Route
          element={
            <PageWithHelmet
              title={PAGES.AUTH.title}
              description={PAGES.AUTH.description}
            >
              <AuthSimpleLayout_Bloc />
            </PageWithHelmet>
          }
        >
          <Route
            path='/login'
            element={
              <PageWithHelmet
                title={PAGES.LOGIN.title}
                description={PAGES.LOGIN.description}
              >
                <SimpleLoginPage_Bloc />
              </PageWithHelmet>
            }
          />
          <Route
            path='/forgot-password'
            element={
              <PageWithHelmet
                title={PAGES.FORGOT_PASSWORD.title}
                description={PAGES.FORGOT_PASSWORD.description}
              >
                <ForgetPassword_Bloc />
              </PageWithHelmet>
            }
          />
          <Route
            path='/auth/reset-password'
            element={
              <PageWithHelmet
                title={PAGES.RESET_PASSWORD.title}
                description={PAGES.RESET_PASSWORD.description}
              >
                <PasswordReset_Bloc />
              </PageWithHelmet>
            }
          />
          <Route
            path='/register'
            element={
              <PageWithHelmet
                title={PAGES.REGISTER.title}
                description={PAGES.REGISTER.description}
              >
                <SimpleRegistrationPage_Bloc />
              </PageWithHelmet>
            }
          />

          <Route
            path='/auth/confirm-email'
            element={
              <PageWithHelmet
                title={PAGES.CONFIRM_EMAIL.title}
                description={PAGES.CONFIRM_EMAIL.description}
              >
                <ConfirmMail_Bloc />
              </PageWithHelmet>
            }
          />
          <Route
            path='/auth/accept-invite'
            element={
              <PageWithHelmet
                title={PAGES.ACCEPT_INVITE.title}
                description={PAGES.ACCEPT_INVITE.description}
              >
                <AcceptInvitePage_Bloc />
              </PageWithHelmet>
            }
          />
          <Route
            path='/contact-us'
            element={
              <PageWithHelmet
                title={PAGES.CONTACT_US.title}
                description={PAGES.CONTACT_US.description}
              >
                <ContactUsPage />
              </PageWithHelmet>
            }
          />
          <Route
            path='/request-demo'
            element={
              <PageWithHelmet
                title={PAGES.CONTACT_US.title}
                description={PAGES.CONTACT_US.description}
              >
                <ContactUsPage />
              </PageWithHelmet>
            }
          />
        </Route>

        {/* PRIVATE - login required, org required, simple layout template */}
        <Route
          element={
            <ProtectedRoute_Bloc>
              <FetchOrgRoute_Bloc>
                <MainLayout_Bloc />
              </FetchOrgRoute_Bloc>
            </ProtectedRoute_Bloc>
          }
        >
          <Route
            path='/dash'
            element={
              <OnboardedRoute>
                <PageWithHelmet
                  title={PAGES.DASHBOARD.title}
                  description={PAGES.DASHBOARD.description}
                >
                  <OrgDashboardPage_Bloc />
                </PageWithHelmet>
              </OnboardedRoute>
            }
          />
          <Route
            path='/org-settings'
            element={
              <PageWithHelmet
                title={PAGES.ORG_SETTINGS.title}
                description={PAGES.ORG_SETTINGS.description}
              >
                <OrgSettingsPage_Bloc />
              </PageWithHelmet>
            }
          />
          <Route
            path='/admin-control'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute
                  service={ORG_SERVICES.BLOCKSCOPE_INTERNAL_CONTROLS}
                >
                  <PageWithHelmet
                    title={PAGES.ADMIN_CONTROL.title}
                    description={PAGES.ADMIN_CONTROL.description}
                  >
                    <AdminControlPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/user-settings'
            element={
              <PageWithHelmet
                title={PAGES.USER_SETTINGS.title}
                description={PAGES.USER_SETTINGS.description}
              >
                <UserSettingsPage_Bloc />
              </PageWithHelmet>
            }
          />

          <Route
            path='/all-blockscope-services'
            element={
              <PageWithHelmet
                title={PAGES.ALL_SERVICES.title}
                description={PAGES.ALL_SERVICES.description}
              >
                <AllServicesPage />
              </PageWithHelmet>
            }
          />

          <Route
            path='/pipeline-details/:pipelineId'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.PIPELINES}>
                  <PageWithHelmet
                    title={PAGES.PIPELINE_DETAILS.title}
                    description={PAGES.PIPELINE_DETAILS.description}
                  >
                    <PipelineDetailsPage_Bloc />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />
          <Route
            path='/create-pipeline'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.PIPELINES}>
                  <PageWithHelmet
                    title={PAGES.PIPELINE_CREATE.title}
                    description={PAGES.PIPELINE_CREATE.description}
                  >
                    <CreatePipelinePage_Bloc />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />
          <Route
            path='/watchtower-details/:watchtowerId'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.WATCHTOWER}>
                  <PageWithHelmet
                    title={PAGES.WATCHTOWER_DETAILS.title}
                    description={PAGES.WATCHTOWER_DETAILS.description}
                  >
                    <WatchtowerDetailsPage_Bloc />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />
          <Route
            path='/create-watchtower'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.WATCHTOWER}>
                  <PageWithHelmet
                    title={PAGES.WATCHTOWER_CREATE.title}
                    description={PAGES.WATCHTOWER_CREATE.description}
                  >
                    <CreateWatchtowerPage_Bloc />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />
          <Route
            path='/watchtower'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.WATCHTOWER}>
                  <PageWithHelmet
                    title={PAGES.WATCHTOWER_MAIN_PAGE.title}
                    description={PAGES.WATCHTOWER_MAIN_PAGE.description}
                  >
                    <WatchtowerMainPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />
          <Route
            path='/pipeline'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.PIPELINES}>
                  <PageWithHelmet
                    title={PAGES.PILELINE_MAIN_PAGE.title}
                    description={PAGES.PILELINE_MAIN_PAGE.description}
                  >
                    <PipelineMainPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />
          <Route
            path='/my-contracts/:contract'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.WATCHTOWER}>
                  <PageWithHelmet
                    title={PAGES.CONTRACTS.title}
                    description={PAGES.CONTRACTS.description}
                  >
                    <ContractDetailsPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />
          <Route
            path='/security'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.ORACLE}>
                  <PageWithHelmet
                    title={PAGES.SECURITY_MONITORING_DASHBOARD.title}
                  >
                    <SecurityMainPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/security/alerts/:alertId'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.ORACLE}>
                  <PageWithHelmet
                    title={PAGES.ALERT_INSTANCES.title}
                    description={PAGES.ALERT_INSTANCES.description}
                  >
                    <AlertInstancePage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/evm-event-directory'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.EVM_EVENT_DIRECTORY}>
                  <PageWithHelmet
                    title={PAGES.EVENT_DIRECTORY.title}
                    description={PAGES.EVENT_DIRECTORY.description}
                  >
                    <EventsDirectoryPage_Bloc />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />
          <Route
            path='/evm-function-directory'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute
                  service={ORG_SERVICES.EVM_FUNCTION_DIRECTORY}
                >
                  <PageWithHelmet
                    title={PAGES.FUNCTION_DIRECTORY.title}
                    description={PAGES.FUNCTION_DIRECTORY.description}
                  >
                    <FunctionsDirectoryPage_Bloc />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/delivery-settings/:deliveryTargetId'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.PIPELINES}>
                  <PageWithHelmet
                    title={PAGES.DELIVERY_SETTINGS.title}
                    description={PAGES.DELIVERY_SETTINGS.description}
                  >
                    <DeliverySettingsSummary />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/transaction-decoder'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.TRANSACTION_DECODER}>
                  <PageWithHelmet
                    title={PAGES.TRANSACTION_DECODER.title}
                    description={PAGES.TRANSACTION_DECODER.description}
                  >
                    <TransactionDecoderSearchPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/transaction-decoder/:chain/:hash'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.TRANSACTION_DECODER}>
                  <PageWithHelmet
                    title={PAGES.TRANSACTION_DECODER.title}
                    description={PAGES.TRANSACTION_DECODER.description}
                  >
                    <TransactionDecoderResultsPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/transaction-simulator'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute
                  service={ORG_SERVICES.TRANSACTION_SIMULATOR}
                >
                  <PageWithHelmet
                    title={PAGES.TRANSACTION_SIMULATOR.title}
                    description={PAGES.TRANSACTION_SIMULATOR.description}
                  >
                    <TransactionSimulatorSearchPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />
          <Route
            path='/transaction-simulator/:chain'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute
                  service={ORG_SERVICES.TRANSACTION_SIMULATOR}
                >
                  <PageWithHelmet
                    title={PAGES.TRANSACTION_SIMULATOR.title}
                    description={PAGES.TRANSACTION_SIMULATOR.description}
                  >
                    <TransactionSimulatorResultsPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/contract-usage'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.CONTRACT_USAGE}>
                  <PageWithHelmet
                    title={PAGES.CONTRACT_USAGE.title}
                    description={PAGES.CONTRACT_USAGE.description}
                  >
                    <ContractUsageSearchPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />
          <Route
            path='/contract-usage/:chain/:contractAddress'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.CONTRACT_USAGE}>
                  <PageWithHelmet
                    title={PAGES.CONTRACT_USAGE.title}
                    description={PAGES.CONTRACT_USAGE.description}
                  >
                    <ContractUsageResultsPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/contract-analysis'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.CONTRACT_BREAKDOWN}>
                  <PageWithHelmet
                    title={PAGES.CONTRACT_BREAKDOWN.title}
                    description={PAGES.CONTRACT_BREAKDOWN.description}
                  >
                    <ContractBreakdownSearchPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />
          <Route
            path='/contract-analysis/:chain/:contractAddress'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.CONTRACT_BREAKDOWN}>
                  <PageWithHelmet
                    title={PAGES.CONTRACT_BREAKDOWN.title}
                    description={PAGES.CONTRACT_BREAKDOWN.description}
                  >
                    <ContractBreakdownResultsPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/entity-interaction'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.ENTITY_INTERACTION}>
                  <PageWithHelmet
                    title={PAGES.ENTITY_INTERACTION.title}
                    description={PAGES.ENTITY_INTERACTION.description}
                  >
                    <EntityInteractionSearchPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />
          <Route
            path='/entity-interaction/:chain/:queryType/'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.ENTITY_INTERACTION}>
                  <PageWithHelmet
                    title={PAGES.ENTITY_INTERACTION.title}
                    description={PAGES.ENTITY_INTERACTION.description}
                  >
                    <EntityInteractionResultsPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />
          <Route
            path='/advanced-search'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.ADVANCED_SEARCH}>
                  <PageWithHelmet
                    title={PAGES.ADVANCED_SEARCH.title}
                    description={PAGES.ADVANCED_SEARCH.description}
                  >
                    <AdvancedSearchPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />
          <Route
            path='/advanced-search/:searchType/:chain'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.ADVANCED_SEARCH}>
                  <PageWithHelmet
                    title={PAGES.ADVANCED_SEARCH.title}
                    description={PAGES.ADVANCED_SEARCH.description}
                  >
                    <AdvancedSearchResultsPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/demixer'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.DEMIXER}>
                  <PageWithHelmet
                    title={PAGES.DEMIXER.title}
                    description={PAGES.DEMIXER.description}
                  >
                    <DemixerPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/block-breakdown'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.BLOCK_BREAKDOWN}>
                  <PageWithHelmet
                    title={PAGES.BLOCK_BREAKDOWN.title}
                    description={PAGES.BLOCK_BREAKDOWN.description}
                  >
                    <BlockSearchPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />
          <Route
            path='/block-breakdown/:chain/:blockNumber'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.BLOCK_BREAKDOWN}>
                  <PageWithHelmet
                    title={PAGES.BLOCK_BREAKDOWN.title}
                    description={PAGES.BLOCK_BREAKDOWN.description}
                  >
                    <BlockBreakdownPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/web3-project-directory'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.WEB3_ENTITIES}>
                  <PageWithHelmet
                    title={PAGES.WEB3_PROJECTS.title}
                    description={PAGES.WEB3_PROJECTS.description}
                  >
                    {/* <ProjectDirectoryPage /> */}
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/web3-project-details/:projectId'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.WEB3_ENTITIES}>
                  <PageWithHelmet
                    title={PAGES.WEB3_PROJECT_DETAILS.title}
                    description={PAGES.WEB3_PROJECT_DETAILS.description}
                  >
                    {/* <ProjectDetailsPage /> */}
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/web3-hack-directory'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.WEB3_HACKS}>
                  <PageWithHelmet
                    title={PAGES.WEB3_HACKS.title}
                    description={PAGES.WEB3_HACKS.description}
                  >
                    {/* <HackDirectoryPage /> */}
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/web3-hack-details/:hackId'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.WEB3_HACKS}>
                  <PageWithHelmet
                    title={PAGES.WEB3_HACK_DETAILS.title}
                    description={PAGES.WEB3_HACK_DETAILS.description}
                  >
                    {/* <HackDetailsPage /> */}
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/web3-chain-details/:chainId'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.WEB3_ENTITIES}>
                  <PageWithHelmet
                    title={PAGES.WEB3_CHAIN_DETAILS.title}
                    description={PAGES.WEB3_CHAIN_DETAILS.description}
                  >
                    <ChainDetailsPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/tracer'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.TRACER_TOKENFLOW}>
                  <PageWithHelmet
                    title={PAGES.TRACER.title}
                    description={PAGES.TRACER.description}
                  >
                    <TracerPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />
          <Route
            path='/my-tracers'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute
                  service={ORG_SERVICES.TRACER_INVESTIGATION}
                >
                  <PageWithHelmet
                    title={PAGES.TRACER_LIST.title}
                    description={PAGES.TRACER_LIST.description}
                  >
                    <MyTracersPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/chat/:sessionId'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.AI_CHAT}>
                  <PageWithHelmet
                    title={PAGES.CHAT.title}
                    description={PAGES.CHAT.description}
                  >
                    <ChatPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />
          {/* <Route
            path='/chat'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.AI_CHAT}>
                  <PageWithHelmet
                    title={PAGES.CHAT.title}
                    description={PAGES.CHAT.description}
                  >
                    <MyChatsPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          /> */}

          <Route
            path='/create-investigation'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.INVESTIGATOR}>
                  <PageWithHelmet
                    title={PAGES.INVESTIGATION_CREATE.title}
                    description={PAGES.INVESTIGATION_CREATE.description}
                  >
                    {/* <CreateInvestigation /> */}
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/my-investigations'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.INVESTIGATOR}>
                  <PageWithHelmet
                    title={PAGES.INVESTIGATIONS.title}
                    description={PAGES.INVESTIGATIONS.description}
                  >
                    {/* <MyInvestigations /> */}
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/investigation-details/:investigationId'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.INVESTIGATOR}>
                  <PageWithHelmet
                    title={PAGES.INVESTIGATIONS.title}
                    description={PAGES.INVESTIGATIONS.description}
                  >
                    {/* <InvestigationDetailsPage /> */}
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/browse-labels'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.WEB3_LABELS}>
                  <PageWithHelmet
                    title={PAGES.LABELS.title}
                    description={PAGES.LABELS.description}
                  >
                    <AllLabelsPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/wallet-profile'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.ADDRESS_ACTIVITY}>
                  <PageWithHelmet
                    title={PAGES.WALLET_PROFILE.title}
                    description={PAGES.WALLET_PROFILE.description}
                  >
                    <WalletSearchPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />
          <Route
            path='/wallet-profile/:chain/:address'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.ADDRESS_ACTIVITY}>
                  <PageWithHelmet
                    title={PAGES.WALLET_PROFILE.title}
                    description={PAGES.WALLET_PROFILE.description}
                  >
                    <WalletProfilePage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/cohort-analysis'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.COHORT_ANALYSIS}>
                  <PageWithHelmet
                    title={PAGES.COHORT_ANALYSIS.title}
                    description={PAGES.COHORT_ANALYSIS.description}
                  >
                    <CohortAnalysisSearchPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />
          <Route
            path='/cohort-analysis/:chain/:addresses'
            element={
              <OnboardedRoute>
                <EnabledServiceRoute service={ORG_SERVICES.COHORT_ANALYSIS}>
                  <PageWithHelmet
                    title={PAGES.COHORT_ANALYSIS.title}
                    description={PAGES.COHORT_ANALYSIS.description}
                  >
                    <CohortAnalysisResultsPage />
                  </PageWithHelmet>
                </EnabledServiceRoute>
              </OnboardedRoute>
            }
          />

          <Route
            path='/my-notifications'
            element={
              <PageWithHelmet
                title={PAGES.NOTIFICATIONS.title}
                description={PAGES.NOTIFICATIONS.description}
              >
                <MyNotifications />
              </PageWithHelmet>
            }
          />

          {/*- ------------- API DOCS ---------------------------  */}
          {/* <Route
            path={FE_DOCUMENTATION_BROWSER_ROUTES.DECODER.TRANSACTION}
            element={
              <OnboardedRoute>
                <PageWithHelmet
                  title={PAGES.DOCS_API_DECODER_TRANSACTION.title}
                >
                  <ApiDocumentationLayout />
                </PageWithHelmet>
              </OnboardedRoute>
            }
          /> */}
          {/* <Route
            path={FE_DOCUMENTATION_BROWSER_ROUTES.LABELS.ADDRESSES}
            element={
              <OnboardedRoute>
                <PageWithHelmet
                  title={PAGES.DOCS_API_LABELS_ADDRESSES.title}
                  description={PAGES.DOCS_API_LABELS_ADDRESSES.description}
                >
                  <ApiDocumentationLayout />
                </PageWithHelmet>
              </OnboardedRoute>
            }
          /> */}
          {/* <Route
            path={FE_DOCUMENTATION_BROWSER_ROUTES.RISK.ADDRESSES}
            element={
              <OnboardedRoute>
                <PageWithHelmet
                  title={PAGES.DOCS_API_RISK_ADDRESSES.title}
                  description={PAGES.DOCS_API_RISK_ADDRESSES.description}
                >
                  <ApiDocumentationLayout />
                </PageWithHelmet>
              </OnboardedRoute>
            }
          /> */}

          <Route
            path='/memos'
            element={
              <PageWithHelmet
                title={PAGES.MEMOS.title}
                description={PAGES.MEMOS.description}
              >
                <MyMemosPage />
              </PageWithHelmet>
            }
          />
          <Route
            path='/guide'
            element={
              <PageWithHelmet
                title={PAGES.GUIDE.title}
                description={PAGES.GUIDE.description}
              >
                <GuidePage />
              </PageWithHelmet>
            }
          />
        </Route>

        {/* PRIVATE - login required, simple layout template  */}
        <Route
          element={
            <ProtectedRoute_Bloc>
              <PageWithHelmet>
                <AuthSimpleLayout_Bloc />
              </PageWithHelmet>
            </ProtectedRoute_Bloc>
          }
        >
          <Route
            path='/select-org'
            element={
              <PageWithHelmet
                title={PAGES.SELECT_ORG.title}
                description={PAGES.SELECT_ORG.description}
              >
                <SelectOrgPage_Bloc />
              </PageWithHelmet>
            }
          />
        </Route>
        <Route
          path='/community/watchtowers'
          element={
            <PageWithHelmet
              title={PAGES.PUBLIC_WATCHTOWER_LIST.title}
              description={PAGES.PUBLIC_WATCHTOWER_LIST.description}
            >
              <PublicWatchtowersPage />
            </PageWithHelmet>
          }
        />

        <Route
          path='/community/watchtowers/:watchtowerId'
          element={
            <PageWithHelmet
              title={PAGES.WATCHTOWER_DETAILS.title}
              description={PAGES.WATCHTOWER_DETAILS.description}
            >
              <PublicWatchtowerDetailsPage />
            </PageWithHelmet>
          }
        />

        {/* PUBLIC - no login required */}
        <Route path='*' element={<Navigate to='/errors/404' replace />} />
      </Routes>

      <AddressPanel />
      <ChatPanel />
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;

