import { ACTION_PLAN_STATUSES } from './types';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  plans: [],
};

const actionPlansSlice = createSlice({
  name: 'actionPlans',
  initialState,
  reducers: {
    createActionPlan: (state, action) => {
      const newPlan = {
        id: crypto.randomUUID(),
        chatSessionId: action.payload.chatSessionId,
        name: action.payload.name,
        sherlockActions: action.payload.sherlockActions,
        prompt: action.payload.prompt,
        logs: [],
        status: ACTION_PLAN_STATUSES.NOT_STARTED,
        currentIndex: 0,
        createdAt: new Date().toISOString(),
      };
      state.plans.push(newPlan);
    },

    updateActionPlanStatus: (state, action) => {
      const plan = state.plans.find((p) => p.id === action.payload.id);
      if (plan) {
        plan.status = action.payload.status;
      }
    },

    addActionPlanLog: (state, action) => {
      const plan = state.plans.find((p) => p.id === action.payload.id);
      if (plan) {
        plan.logs.push(action.payload.log);
      }
    },

    updateCurrentIndex: (state, action) => {
      const plan = state.plans.find((p) => p.id === action.payload.id);
      if (plan) {
        plan.currentIndex = action.payload.index;
      }
    },

    resetActionPlan: (state, action) => {
      const plan = state.plans.find((p) => p.id === action.payload.id);
      if (plan) {
        plan.logs = [];
        plan.status = ACTION_PLAN_STATUSES.NOT_STARTED;
        plan.currentIndex = 0;
      }
    },

    clearActionPlanLogs: (state, action) => {
      const plan = state.plans.find((p) => p.id === action.payload.id);
      if (plan) {
        plan.logs = [];
      }
    },

    deleteActionPlan: (state, action) => {
      state.plans = state.plans.filter((p) => p.id !== action.payload.id);
    },

    clearAllActionPlans: (state) => {
      state.plans = [];
    },
  },
});

export const {
  createActionPlan,
  updateActionPlanStatus,
  addActionPlanLog,
  updateCurrentIndex,
  resetActionPlan,
  clearActionPlanLogs,
  deleteActionPlan,
  clearAllActionPlans,
} = actionPlansSlice.actions;

export default actionPlansSlice.reducer;

