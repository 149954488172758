import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Input } from 'reactstrap';

import PropTypes from 'prop-types';

import Flex from 'blockscope/components/common/Flex';

export const PageControlsV2 = ({
  page,
  nextPage,
  prevPage,
  setPage,
  canNextPage,
  canPrevPage,
  rowCount,
  rowsPerPage,
  showCount,
  prevButtonText = 'Prev',
  nextButtonText = 'Next',
  limitPage,
  pageInput = true,
}) => {
  const [displayText, setDisplayText] = useState('');
  const [inputPage, setInputPage] = useState(page);

  useEffect(() => {
    if (showCount) {
      const start = 1 + (page - 1) * rowsPerPage;
      const end = Math.min(page * rowsPerPage, rowCount);
      setDisplayText(`${start} - ${end} of ${Math.ceil(rowCount)}`);
    }
    if (page != inputPage) {
      setInputPage(page);
    }
  }, [page, rowCount, rowsPerPage, showCount]);

  return (
    <Flex className='align-items-center justify-content-between'>
      {showCount && (
        <p className='mb-0'>
          <span className='fs--1 mx-1'>{displayText}</span>
        </p>
      )}
      <Flex alignItems='center' className='fs--1'>
        <Flex alignItems='center' className='fs--1'>
          <p className='mb-0'>
            <span className='fs--1 mx-1'>Page</span>
          </p>
          {pageInput ? (
            <Input
              value={inputPage}
              style={{ width: '4rem' }}
              type='number'
              className='px-1 py-1'
              onChange={(e) => {
                const newPage = parseInt(e.target.value) || 0;
                if (
                  newPage !== 0 &&
                  newPage <= Math.ceil(limitPage || rowCount / rowsPerPage)
                ) {
                  setPage(newPage - 1);
                }
                setInputPage(newPage);
              }}
            />
          ) : (
            <p className='mb-0 fs--1 mx-1'>{page}</p>
          )}

          <p className='mb-0'>
            <span className='fs--1 mx-1'>
              of {Math.ceil(rowCount / rowsPerPage)}
            </span>
          </p>
        </Flex>
      </Flex>

      <Flex>
        <Button
          variant='falcon-default'
          size='sm'
          onClick={() => {
            prevPage();
          }}
          disabled={!canPrevPage}
          className='me-1'
        >
          {prevButtonText}
        </Button>
        <Button
          variant='falcon-default'
          size='sm'
          onClick={() => {
            nextPage();
          }}
          disabled={!canNextPage}
        >
          {nextButtonText}
        </Button>
      </Flex>
    </Flex>
  );
};
PageControlsV2.propTypes = {
  page: PropTypes.number,
  setPage: PropTypes.func,
  nextPage: PropTypes.func,
  prevPage: PropTypes.func,
  canNextPage: PropTypes.bool,
  canPrevPage: PropTypes.bool,
  rowCount: PropTypes.number,
  rowsPerPage: PropTypes.number,
  showCount: PropTypes.bool,
  prevButtonText: PropTypes.string,
  nextButtonText: PropTypes.string,
  limitPage: PropTypes.number,
  pageInput: PropTypes.bool,
};

export default PageControlsV2;

