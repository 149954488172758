import { CHAINS } from 'blockscope/static/supportedChains';

export const ADVANCED_SEARCH_TYPES = {
  CUSTOM: 'custom',
  ENTITY_INTERACTION: 'entity-interaction',
  CONTRACT_FUNCTIONS: 'contract-functions',
  CONTRACT_EVENTS: 'contract-events',
  ERC20: 'erc20',
  ERC721: 'erc721',
};
export const SEARCH_TYPE_LABELS = {
  [ADVANCED_SEARCH_TYPES.CUSTOM]: 'Custom',
  [ADVANCED_SEARCH_TYPES.ENTITY_INTERACTION]: 'Entity Interaction',
  [ADVANCED_SEARCH_TYPES.CONTRACT_FUNCTIONS]: 'Contract Functions',
  [ADVANCED_SEARCH_TYPES.CONTRACT_EVENTS]: 'Contract Events',
  [ADVANCED_SEARCH_TYPES.ERC20]: 'ERC20 Transfers',
  [ADVANCED_SEARCH_TYPES.ERC721]: 'NFT Transfers',
};

export const searchTypeOptions = [
  {
    label: SEARCH_TYPE_LABELS[ADVANCED_SEARCH_TYPES.CUSTOM],
    value: ADVANCED_SEARCH_TYPES.CUSTOM,
  },
  {
    label: SEARCH_TYPE_LABELS[ADVANCED_SEARCH_TYPES.ENTITY_INTERACTION],
    value: ADVANCED_SEARCH_TYPES.ENTITY_INTERACTION,
  },
  {
    label: SEARCH_TYPE_LABELS[ADVANCED_SEARCH_TYPES.CONTRACT_FUNCTIONS],
    value: ADVANCED_SEARCH_TYPES.CONTRACT_FUNCTIONS,
  },
  {
    label: SEARCH_TYPE_LABELS[ADVANCED_SEARCH_TYPES.CONTRACT_EVENTS],
    value: ADVANCED_SEARCH_TYPES.CONTRACT_EVENTS,
  },
  {
    label: SEARCH_TYPE_LABELS[ADVANCED_SEARCH_TYPES.ERC20],

    value: ADVANCED_SEARCH_TYPES.ERC20,
  },
  {
    label: SEARCH_TYPE_LABELS[ADVANCED_SEARCH_TYPES.ERC721],
    value: ADVANCED_SEARCH_TYPES.ERC721,
  },
];

export const SOURCE_TYPES = {
  TRANSACTION: 'transactions',
  TRACE: 'traces',
  LOGS: 'logs',
};

export const customSearchSourceOptions = [
  { label: 'Transactions', value: SOURCE_TYPES.TRANSACTION },
  { label: 'Traces', value: SOURCE_TYPES.TRACE },
];

export const ENTITY_INTERACTION_QUERY_TYPES = {
  TRANSACTION: 'transactions',
  TRACES: 'traces',
  TOKEN_TRANSFERS: 'token-transfers',
};
export const entityInteractionQueryTypeOptions = [
  {
    label: 'Transactions',
    value: ENTITY_INTERACTION_QUERY_TYPES.TRANSACTION,
  },
  { label: 'Traces', value: ENTITY_INTERACTION_QUERY_TYPES.TRACES },
  {
    label: 'Token Transfers',
    value: ENTITY_INTERACTION_QUERY_TYPES.TOKEN_TRANSFERS,
  },
];

export const CHAIN_QUERY_MAP = {
  [CHAINS.ARBITRUM]: [
    ENTITY_INTERACTION_QUERY_TYPES.TRANSACTION,
    ENTITY_INTERACTION_QUERY_TYPES.TOKEN_TRANSFERS,
  ],
  [CHAINS.AVALANCHE_C]: [
    ENTITY_INTERACTION_QUERY_TYPES.TRANSACTION,
    ENTITY_INTERACTION_QUERY_TYPES.TOKEN_TRANSFERS,
  ],
  [CHAINS.ETHEREUM]: [
    ENTITY_INTERACTION_QUERY_TYPES.TRANSACTION,
    ENTITY_INTERACTION_QUERY_TYPES.TRACES,
    ENTITY_INTERACTION_QUERY_TYPES.TOKEN_TRANSFERS,
  ],
  [CHAINS.FANTOM]: [
    ENTITY_INTERACTION_QUERY_TYPES.TRANSACTION,
    ENTITY_INTERACTION_QUERY_TYPES.TOKEN_TRANSFERS,
  ],
  [CHAINS.OPTIMISM]: [
    ENTITY_INTERACTION_QUERY_TYPES.TRANSACTION,
    ENTITY_INTERACTION_QUERY_TYPES.TOKEN_TRANSFERS,
  ],
  [CHAINS.POLYGON]: [
    ENTITY_INTERACTION_QUERY_TYPES.TRANSACTION,
    ENTITY_INTERACTION_QUERY_TYPES.TOKEN_TRANSFERS,
  ],
  [CHAINS.TRON]: [
    ENTITY_INTERACTION_QUERY_TYPES.TRANSACTION,
    ENTITY_INTERACTION_QUERY_TYPES.TOKEN_TRANSFERS,
  ],
  [CHAINS.BASE]: [
    ENTITY_INTERACTION_QUERY_TYPES.TRANSACTION,
    ENTITY_INTERACTION_QUERY_TYPES.TRACES,
    ENTITY_INTERACTION_QUERY_TYPES.TOKEN_TRANSFERS,
  ],
};
