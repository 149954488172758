export const ACTION_PLAN_STATUSES = {
  NOT_STARTED: 'Not started',
  STARTED: 'Navigation started',
  COMPLETED: 'Navigation completed',
  FAILED: 'Navigation failed',
};

export const SHERLOCK_ACTIONS = {
  OPEN_PROFILER_TAB: 'sherlockAction_openProfilerTab_screenshotPage',
  OPEN_CONTRACT_ANALYSIS_TAB:
    'sherlockAction_openContractAnalysisTab_screenshotPage',
  OPEN_DECODER_TAB: 'sherlockAction_openDecoderTab_screenshotPage',
  SEARCH_ADDRESS: 'profile_txns_search',
  WAIT: 'wait',
  CURRENT_PAGE: 'sherlockAction_CurrentPage_screenshotPage',

  // Add other action types here as we expand functionality
  // COMPARE_WALLETS: 'sherlockAction_CompareWallets',
  // ANALYZE_TRANSACTIONS: 'sherlockAction_AnalyzeTransactions'
};

